export const PUBLIC_ROUTE = {
  ROOT: '/',
  LOGIN: '/login',
  LOGIN_MOBILE: '/login-mobile',
  LANDING: '/',
  PAGE_404: '/404',
  PAGE_500: '/500',
  FRIEND_SIGNUP: '/friend-signup',
  ADMIN_SIGNUP: '/admin-signup',
  RECOVER: '/recover',
  RESET_PASSWORD: '/reset-password',
  PAYMENT_SUCCESS: '/payment-success',
  PAYMENT_CANCEL: '/payment-cancel',
  QRCODE_SHARE: '/qrcode-share',
};

export const PRIVATE_ROUTE = {
  MAIN: '/main',
  PROFILE: '/profile',

  INTEGRATED_CONTROL_MONITOR: '/integrated-control-monitor',
  PARKING_CONTROL_MONITOR: '/parking-control-monitor',
  VEHICLE_CONTROL_MONITOR: '/vehicle-control-monitor',
  DISCOUNT_APPLY: '/discount-apply',
  DISCOUNT_PURCHASE: '/discount-purchase',

  USER_ADMIN_MANAGE: '/user-admin',
  USER_GENERAL_MANAGE: '/user-general',
  BASIC_INFO_MANAGE: '/basic-info',

  VEHICLE_MANAGE: '/vehicle',
  VEHICLE_UNRECOGNIZED: '/vehicle-unrecognized',
  VEHICLE_LONG_TERM: '/vehicle-long-term',

  NOTICE_MANAGE: '/notice',
  FEEDBACK_MANAGE: '/feedback',
  AFTER_SERVICE_MANAGE: '/as',
  SETTING_PUSH: '/setting-push',

  TICKET_MANAGE: '/ticket',
  TICKET_WAITLIST: '/ticket-waitlist',
  TICKET_EXTENSION: '/ticket-extension',
  TICKET_DISTRIBUTION: '/ticket-distribution',
  TICKET_RAFFLE: '/ticket-raffle',

  PAYMENT_COUPON: '/payment-coupon',
  PAYMENT_VEHICLE: '/payment-vehicle',
  PAYMENT_TICKET: '/payment-ticket',
  POSTPAY: '/postpay',

  RESERVATION_MANAGE: '/reservation',
  PARKING_LOT_MANAGE: '/parking-lot-manage',
  PARKING_LOT_POLICY: '/parking-lot-policy',
  DISCOUNT_COUPON_POLICY: '/discount-coupon-policy',
  DISCOUNT_COUPON_KEYS: '/discount-coupon-keys',
  FACILITY_MANAGE: '/facility',

  FRIEND_MANAGE: '/friend',
  FRIEND_TICKET: '/friend-ticket',
  /** @deprecated */ FRIEND_TICKET_USERS: '/friend-ticket-users',

  FRIEND_DISCOUNT_REGISTER: '/friend-discount-register',
  // FRIEND_DISCOUNT_STATUS: '/friend-discount-status',
  FRIEND_DISCOUNT_CHARGE: '/friend-discount-charge',
  FRIEND_DISCOUNT_QRCODE: '/friend-discount-qrcode',
  FRIEND_DISCOUNT_APPLICATION: '/friend-discount-application',
  FRIEND_DISCOUNT_LINK: '/friend-discount-link',
  FRIEND_PARKING_LOT: '/friend-parking-lot',
  FRIEND_NOTICE: '/friend-notice',

  VOC: '/voc',
  VOC_LIST: '/voc-list',

  REPORT_CLOSINGS: '/report-closings',
  REPORT_SALES: '/report-sales',
  REPORT_POSTPAY: '/report-postpay',

  REAL_TIME_SALES: '/real-time-sales',

  CLOUDWATCH: '/cloudwatch',
  MANNED_SETTLEMENT: '/manned-settlement',
  TOWER_MANAGE: '/tower-manage',
};
