import React, { useEffect } from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { ConfigProvider, BackTop } from 'antd';
import koKR from 'antd/es/locale/ko_KR';
import { SWRConfig } from 'swr';
import 'moment/locale/ko'; // TODO: DatePicker 등 moment 사용하는 컴포넌트들 date-fns로 변경 (locale 방법 찾아야됨)

import Router from './routes/Router';
import useAxios from './hooks/useAxios';
import './App.less';
import { RecoilRoot } from 'recoil';

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

function App() {
  useEffect(() => {
    const suffix = ' (localhost)';
    const isLocalhost =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1';

    if (isLocalhost && !document.title.endsWith(suffix)) {
      document.title += suffix;
    }

    return () => {
      if (document.title.endsWith(suffix))
        document.title = document.title.slice(0, -suffix.length);
    };
  });

  return (
    <ThemeSwitcherProvider themeMap={themes} defaultTheme="light">
      <RecoilRoot>
        <ConfigProvider locale={koKR}>
          <SWRWrapper>
            <BackTop />
            <Router />
          </SWRWrapper>
        </ConfigProvider>
      </RecoilRoot>
    </ThemeSwitcherProvider>
  );
}

function SWRWrapper({ children }: { children: React.ReactNode }) {
  const axios = useAxios();
  const swrConfig = {
    fetcher(url: string, params: object) {
      return axios.get(url, { params }).then((res) => res.data);
    },
  };
  return <SWRConfig value={swrConfig}>{children}</SWRConfig>;
}

export default App;
