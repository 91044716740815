import { ReactNode, useCallback, useState } from 'react';
import { notification, Space, Tooltip, Typography } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { CloseOutlined } from '@ant-design/icons';
import { useLongPress } from 'use-long-press';

import { format } from 'date-fns';
import { DateValue } from '@utils';
import { useRecoilValue } from 'recoil';
import placementState from '@recoil/placement';

import { useLocation } from 'react-router-dom';
import { PRIVATE_ROUTE } from '@routes/routes.constants';

const banned = [PRIVATE_ROUTE.TOWER_MANAGE];

type KeyArgsProps = ArgsProps & Required<Pick<ArgsProps, 'key'>>;
export interface OpenerProps extends KeyArgsProps {
  time?: DateValue;
  actions?: ReactNode;
}

export type Opener = (p: OpenerProps) => void;

const useOpener: () => Opener = () => {
  const { pathname } = useLocation();
  const [opened] = useState(new Set<string>());
  const [closed] = useState(new Set<string>());
  const placement = useRecoilValue(placementState);

  const closeAll = useCallback(
    () => opened.forEach((k) => notification.close(k)),
    [opened]
  );
  const bind = useLongPress(() => closeAll(), { threshold: 750 });

  return useCallback(
    ({ key, time, actions, message, description, ...props }) => {
      if (closed.has(key)) return;
      if (banned.some((b) => pathname.startsWith(b))) return;

      const title = time ? (
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          {message}
          <Tooltip
            placement="left"
            title={format(new Date(time), 'yyyy년 M월 d일, HH:mm:ss')}
          >
            <Typography.Text type="secondary">
              <time dateTime={new Date(time).toISOString()}>
                {format(new Date(time), 'HH:mm')}
              </time>
            </Typography.Text>
          </Tooltip>
        </Space>
      ) : (
        message
      );

      const content = actions ? (
        <Space direction="vertical" style={{ position: 'relative' }}>
          {description}
          <Space style={{ position: 'absolute', bottom: 0, right: 0 }}>
            {actions}
          </Space>
        </Space>
      ) : (
        description
      );

      const closeIcon = (
        <CloseOutlined
          {...bind}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            closeAll();
          }}
        />
      );

      notification.open({
        ...props,
        key,
        duration: 0,
        message: title,
        description: content,
        closeIcon,
        placement,
        onClose: () => closed.add(key),
      });
      opened.add(key);
    },
    [bind, closeAll, closed, opened, pathname, placement]
  );
};

export default useOpener;
