import { FC } from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalProps } from 'antd';
import Info, { InfoProps } from '@components/Info';

export interface InfoModalProps extends ModalProps, InfoProps {
  setVisible: (visible: boolean) => void;
}

const InfoModal: FC<InfoModalProps> = ({
  vaId,
  revalidate,
  setVisible,
  ...props
}) => (
  <VStackModal
    {...props}
    width={600}
    title="정보 조회"
    destroyOnClose
    closable={false}
    footer={<Button onClick={() => setVisible(false)}>확인</Button>}
  >
    <Info vaId={vaId} revalidate={revalidate} />
  </VStackModal>
);

export default InfoModal;

const VStackModal = styled(Modal)`
  .ant-modal-body > * + * {
    margin-top: 16px;
  }
`;
