type TSetQuery = (
  query: any,
  updateType?: 'replace' | 'replaceIn' | 'push' | 'pushIn' | undefined
) => void;

export default (setQuery: TSetQuery) => (_: any, _filters: any, sorter: any) => {
  const { order, field, columnKey } = sorter;
  setQuery(
    {
      sort: order
        ? `${order === 'descend' ? '-' : ''}${
            columnKey || (Array.isArray(field) ? field.join('.') : field)
          }`
        : undefined,
    },
    'replaceIn'
  );
};
