import { Select, SelectProps } from 'antd';
import { useRecoilValue } from 'recoil';
import currentSiteState from '@recoil/current-site';
import { userInfoState } from '@recoil/auth';
import useSWR from 'swr';
import { DiscountCouponKey } from '@utils/models';
import { useCallback, useMemo } from 'react';
import useAxios from '@hooks/useAxios';

interface KeysData {
  discountCouponKeys: DiscountCouponKey[];
}

export const useCoupons = () => {
  const axios = useAxios();

  const currentSite = useRecoilValue(currentSiteState)!;
  const siteId = useMemo(() => currentSite._id, [currentSite._id]);

  const userInfo = useRecoilValue(userInfoState);
  const isFriend = useMemo(
    () => ['friend', 'owner', 'arcade'].includes(userInfo.permissionGroup.tag),
    [userInfo.permissionGroup.tag]
  );

  const { data, revalidate, isValidating } = useSWR<KeysData>(() =>
    isFriend
      ? `/discount-coupon-keys/coupon?owner=${userInfo._id}&ownerModel=Admin`
      : `/discount-coupon-keys?siteId=${siteId}`
  );

  const coupons = useMemo(() => data?.discountCouponKeys, [data]);

  const createCoupon = useCallback(
    async (discountCouponKey: string) => {
      if (isFriend) {
        const coupons = (
          await axios.get<KeysData>(
            `/discount-coupon-keys/coupon?owner=${userInfo._id}&ownerModel=Admin`
          )
        ).data?.discountCouponKeys;

        const nextCoupon = coupons?.find(
          (k) => k._id === discountCouponKey
        )?.nextCoupon;
        revalidate();
        return nextCoupon;
      } else {
        return axios
          .post(`/discount-coupons?siteId=${siteId}`, { discountCouponKey })
          .then(({ data }) => data._id);
      }
    },
    [isFriend, axios, userInfo._id, revalidate, siteId]
  );

  return {
    coupons,
    revalidate,
    isValidating,
    isFriend,
    createCoupon,
  };
};

export interface CouponSelectProps extends SelectProps<string> {
  value?: string;
  onChange?: (value: string) => void;
  onChangeDocument?: (document?: DiscountCouponKey) => void;
  coupons?: DiscountCouponKey[];
  notSelect?: boolean;
  notSelectValue?: any;
  notSelectText?: any;
}

const CouponSelect = ({
  value,
  onChange,
  onChangeDocument,
  coupons,
  notSelect,
  notSelectValue,
  notSelectText,
  style,
  placeholder,
  ...props
}: CouponSelectProps) => (
  <Select
    style={{ width: '100%', ...style }}
    placeholder={placeholder || '할인권 선택'}
    value={value}
    onChange={(value) => {
      onChange?.(value);
      onChangeDocument?.(coupons?.find((c) => c._id === value));
    }}
    loading={!coupons}
    {...props}
  >
    {notSelect && (
      <Select.Option value={notSelectValue}>
        {notSelectText || '선택안함'}
      </Select.Option>
    )}
    {coupons &&
      coupons.map((key) => (
        <Select.Option key={key._id} value={key._id}>
          {key.name}
          {'count' in key ? ` (${key.count.toLocaleString()}개 남음)` : ''}
        </Select.Option>
      ))}
  </Select>
);

export default CouponSelect;
