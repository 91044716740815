import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { DatePicker, Form, Input, Modal, Button, message } from 'antd';

import useAxios from '@hooks/useAxios';
import { PRIVATE_ROUTE } from '@routes/routes.constants';
import currentSiteState from '@recoil/current-site';
import { VEHICLE_REGEX } from '@utils/regex';
import { VehicleAccess } from '@utils/models';

const VA_URL = '/vehicle-accesses';
interface IModal {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  revalidate: () => void;
  isEntry?: boolean;
  initialVehicle?: string;
  goPage?: boolean;
  onCreated?: (va: VehicleAccess) => void;
}

export default ({
  visible,
  setVisible,
  revalidate,
  onCreated,
  isEntry = true,
  initialVehicle,
  goPage = true,
}: IModal) => {
  const axios = useAxios();
  const { push } = useHistory();
  const currentSite = useRecoilValue(currentSiteState)!;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const name = useMemo(() => (isEntry ? '입차' : '출차'), [isEntry]);
  const field = useMemo(() => (isEntry ? 'entry' : 'exit'), [isEntry]);
  const endpoint = useMemo(() => (isEntry ? 'entrance' : 'exit'), [isEntry]);

  const submit = useCallback(async () => {
    try {
      const { vehicle, accessedAt } = await form.validateFields();
      if (vehicle.match(VEHICLE_REGEX) === null)
        return message.error('차량번호를 제대로 입력해 주세요!');

      setLoading(true);
      const { data: va } = await axios.post<VehicleAccess>(
        `${VA_URL}/${endpoint}?siteId=${currentSite._id}`,
        { vehicle, [field]: { accessedAt } }
      );

      if (va?._id) {
        onCreated?.(va);
        goPage && push(`${PRIVATE_ROUTE.VEHICLE_MANAGE}/info/${va._id}`);
      }

      revalidate();
      setVisible(false);
      message.success(`${vehicle} 차량이 수동 ${name}로 추가되었습니다.`);
    } catch (err: any) {
      message.error(
        err?.response?.data?.message || `수동 ${name}를 실패했습니다.`
      );
    } finally {
      setLoading(false);
    }
  }, [
    axios,
    currentSite._id,
    name,
    field,
    endpoint,
    form,
    goPage,
    onCreated,
    push,
    revalidate,
    setVisible,
  ]);

  return (
    <Modal
      title={`수동 ${name}`}
      visible={visible}
      closable={false}
      footer={[
        <Button key="cancel" onClick={() => setVisible(false)}>
          취소
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={submit}>
          등록
        </Button>,
      ]}
      width="400px"
      destroyOnClose={true}
    >
      <Form
        form={form}
        preserve={false}
        layout="vertical"
        initialValues={{ vehicle: initialVehicle }}
      >
        <Form.Item name="vehicle" label="차량번호" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="accessedAt"
          label={`${name}일시`}
          rules={[{ required: true }]}
        >
          <DatePicker showTime={true} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
