import { FC, useCallback, useState, useMemo } from 'react';
import { Button, List, ListProps, message, Space } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import useAxios from '@hooks/useAxios';
import { useRecoilValue } from 'recoil';
import { userInfoState } from '@recoil/auth';

import { renderDate } from '@utils/index';
import { DiscountCoupon, VehicleAccess } from '@utils/models';

interface Props extends ListProps<DiscountCoupon> {
  va?: VehicleAccess;
  small?: boolean;
  revalidate?: () => void;
  isValidating?: boolean;
}

const CouponList: FC<Props> = ({
  va,
  small,
  revalidate,
  isValidating,
  ...props
}) => {
  const axios = useAxios();
  const userInfo = useRecoilValue(userInfoState);
  const isManager = useMemo(
    () => ['super', 'manager'].includes(userInfo.permissionGroup.tag),
    [userInfo.permissionGroup.tag]
  );

  const [loading, setLoading] = useState(false);

  const deleteCoupon = useCallback(
    async (coupon: DiscountCoupon) => {
      if (!va) return;
      if (va.payment_id) return message.error('이미 정산된 차량입니다.');

      setLoading(true);
      const discountCouponList = va.payment.discountCouponList.flatMap(
        ({ _id }) => (_id !== coupon._id ? [_id] : [])
      );

      try {
        await axios.patch(`/vehicle-accesses/${va._id}`, {
          payment: { discountCouponList },
        });

        revalidate?.();
        message.success(`${va.vehicle} 차량에 할인권이 삭제되었습니다.`);
      } catch (err: any) {
        message.error(`할인권 삭제 중 오류가 발생했습니다.`);
      } finally {
        setLoading(false);
      }
    },
    [axios, va, revalidate]
  );

  const renderItem = (coupon: DiscountCoupon) => (
    <List.Item
      key={coupon._id}
      actions={[
        <Button
          size={small ? 'small' : undefined}
          danger
          loading={loading}
          disabled={
            isValidating || (!isManager && coupon.owner?._id !== userInfo._id)
          }
          onClick={() => deleteCoupon(coupon)}
        >
          삭제
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={
          <span>
            {coupon.isQR && (
              <QrcodeOutlined
                title="QR Code 할인권"
                style={{ marginRight: '0.25em', fontSize: '1em' }}
              />
            )}
            {coupon.discountCouponKey.name}
          </span>
        }
        description={
          <Space size={small ? 'small' : undefined}>
            {coupon.uses?.applyAt && (
              <span>{renderDate(coupon.uses.applyAt)}</span>
            )}

            {coupon?.owner && (
              <span>
                적용자:{' '}
                {coupon.ownerModel === 'User'
                  ? coupon.owner.info.name
                  : coupon.owner.name}
              </span>
            )}
          </Space>
        }
      />
    </List.Item>
  );

  return (
    <SpecialList
      bordered
      header="적용될 할인권 목록"
      size={small ? 'small' : 'default'}
      loading={!va}
      dataSource={va?.payment?.discountCouponList}
      renderItem={renderItem}
      {...props}
    />
  );
};

export default CouponList;

const SpecialList: typeof List = styled(List)`
  .ant-list-item-action {
    margin-left: 0.75em;
  }

  .ant-list-item-meta-description {
    font-size: 0.75em;
  }
`;
