import styled from 'styled-components';

export default styled.h3`
  font-size: 19px;
  font-weight: 500;
  width: 100%;
  margin-right: 17px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media only screen and (max-width: 767px) {
    margin: 0 10px;
    margin-bottom: 20px;
  }

  &:before {
    content: '';
    width: 5px;
    height: 40px;
    background-color: rgb(228, 230, 233);
    display: flex;
    margin: 0 15px 0 0;

    body[data-theme='dark'] & {
      background-color: #303030;
    }
  }

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgb(228, 230, 233);
    display: flex;
    margin: 0 0 0 15px;

    body[data-theme='dark'] & {
      background-color: #303030;
    }
  }

  .ant-breadcrumb {
    font-size: 16px;
  }
`;
