import loadable from '@loadable/component';

interface IIcon {
  type: string;
}

const Icon = loadable(
  ({ type }: IIcon) => import(`@ant-design/icons/es/icons/${type}`),
  {
    cacheKey: (props) => props.type,
  }
);

export default Icon;
