import { FC } from 'react';
import styled from 'styled-components';
import eyevacsLogo from '@assets/mapmarker.svg';
import { Site } from '@utils/models';

interface SiteNameProps {
  site: Site;
  mode?: 'normal' | 'dropdown';
}

const SiteName: FC<SiteNameProps> = ({ site, mode }) => {
  const Logo = mode === 'dropdown' ? DropdownIcon : Icon;

  return site.region === 'siliconbridge' ? (
    <LogoWrapper>
      <Logo src={eyevacsLogo} alt="SBI" />
      <span>{site.name}</span>
    </LogoWrapper>
  ) : (
    <span>{site.name}</span>
  );
};

export const LogoWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.25rem;
`;

export const Icon = styled.img`
  height: 18px;
`;

const DropdownIcon = styled(Icon)`
  display: none;
  .ant-select-dropdown & {
    display: initial;
  }
`;

export default SiteName;
