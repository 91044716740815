import { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  Button,
  Switch,
  Space,
  message,
  Typography,
  Card,
  Image,
  Radio,
  Divider,
  Empty,
} from 'antd';

import useAxios from '@hooks/useAxios';
import { setLocked, setPassing } from './endpoint';

import { format } from 'date-fns';
import { IMAGE_CDN, IMAGE_FALLBACK } from '@utils';
import { AccessLog, Facility, VehicleAccess } from '@utils/models';

import VehicleTable, { VehicleTableProps } from './VehicleTable';

import RestartButton from './RestartButton';
import SeasonTicketSpan from './SeasonTicketSpan';
import FacilityTag from './FacilityTag';
import RotationTag from './RotationTag';
import AccessLogText from '@components/AccessLogText';

import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from '@routes/routes.constants';
import { EyeOutlined } from '@ant-design/icons';

// @file_context_0에 따라 AccessLogType에 'internal_entrance'와 'internal_exit'를 추가합니다.
export type AccessLogType =
  | 'entry'
  | 'exit'
  | 'internal_entrance'
  | 'internal_exit';

const otherType = (type: AccessLogType) => {
  switch (type) {
    case 'entry':
      return 'exit';
    case 'exit':
      return 'entry';
    case 'internal_entrance':
      return 'internal_exit';
    case 'internal_exit':
      return 'internal_entrance';
  }
};

const manualName = (type: AccessLogType) => {
  switch (type) {
    case 'entry':
      return '수동입차';
    case 'exit':
      return '수동출차';
    case 'internal_entrance':
      return '수동내부입차';
    case 'internal_exit':
      return '수동내부출차';
  }
};

// (열림고정 or 정상) 컴포넌트
interface LPRModeProps {
  facility: Facility;
  revalidate: () => void;
}

const LPRMode: FC<LPRModeProps> = ({ facility, revalidate }) => {
  const axios = useAxios();
  const text = (v: boolean) => (v ? '열림고정' : '정상');

  return (
    <div>
      <SwitchLabel>정상</SwitchLabel>
      <Switch
        style={{ margin: '0 8px' }}
        defaultChecked={facility.configuration?.mode === 'up_lock'}
        onChange={(v) =>
          setLocked(axios, facility, v)
            .then(() => message.success(`${text(v)}으로 설정되었습니다.`))
            .catch(() => message.error('설정 중 오류가 발생했습니다.'))
            .finally(revalidate)
        }
      />
      <SwitchLabel>열림고정</SwitchLabel>
    </div>
  );
};

// (프리패스 or 경비확인) 컴포넌트
interface LPROpenProps {
  facility: Facility;
  revalidate: () => void;
}

const LPROpen: FC<LPROpenProps> = ({ facility, revalidate }) => {
  const axios = useAxios();
  const label = (v: boolean) => (v ? '프리패스' : '경비확인');

  const [isLoading, setLoading] = useState(false);

  return (
    <Radio.Group
      disabled={isLoading}
      optionType="button"
      options={[
        { label: label(true), value: true },
        { label: label(false), value: false },
      ]}
      value={facility?.configuration?.recog_gate_open}
      onChange={(e) => {
        setLoading(true);
        setPassing(axios, facility, e.target.value)
          .then(() => {
            revalidate();
            message.success(`${label(e.target.value)} 모드로 설정되었습니다.`);
          })
          .catch(() => message.error('설정 중 오류가 발생했습니다.'))
          .finally(() => setLoading(false));
      }}
    />
  );
};

interface CoverProps {
  log?: AccessLog;
  otherLog?: AccessLog;
  maxCount?: number;
}

const Cover: FC<CoverProps> = ({ log, otherLog, maxCount }) => {
  const mainImage = useMemo(
    () =>
      log && otherLog
        ? log.image?.file || otherLog.image?.file
        : log?.image?.file,
    [log, otherLog]
  );

  const extraImages = useMemo(
    () => log?.extraImages?.map((i) => i?.file),
    [log]
  );

  const images = useMemo(
    () => [
      ...(mainImage ? [mainImage] : []),
      ...(extraImages?.length ? extraImages : []),
      ...(!mainImage && !extraImages?.length ? [null] : []),
    ],
    [mainImage, extraImages]
  );

  return (
    <Image.PreviewGroup>
      {images.map((image, index) => (
        <Image
          key={index}
          width="100%"
          fallback={IMAGE_FALLBACK}
          src={image ? `${IMAGE_CDN}/${image}` : IMAGE_FALLBACK}
          style={maxCount && index >= maxCount ? { display: 'none' } : {}}
          preview={
            !!image && {
              mask: (
                <span>
                  <EyeOutlined /> 자세히 보기
                  {images.length > 1 && ` (${index + 1}/${images.length})`}
                </span>
              ),
            }
          }
        />
      ))}
    </Image.PreviewGroup>
  );
};

interface ContentProps {
  log?: AccessLog;
  va?: VehicleAccess;
}

const Content: FC<ContentProps> = ({ va, log }) => {
  return (
    <Space wrap direction="vertical" style={{ flex: 1, padding: '20px' }}>
      {va && log ? (
        <>
          <Space align="center" style={{ flex: 1 }}>
            <Link
              to={`${PRIVATE_ROUTE.VEHICLE_MANAGE}/info/${va._id}`}
              style={{ fontSize: '1.3rem', whiteSpace: 'nowrap' }}
            >
              {va.vehicle || '미인식'}
            </Link>

            <Typography.Text
              type="secondary"
              style={{ fontSize: '1rem', whiteSpace: 'nowrap' }}
            >
              {va.payment.seasonTicket ? '정기권' : '일반'}
            </Typography.Text>

            <RotationTag
              rotation={va.rotation}
              style={{ fontSize: '1rem', whiteSpace: 'nowrap' }}
            />
          </Space>

          <div>
            <AccessLogText log={log} />
            <SeasonTicketSpan seasonTicket={va.payment.seasonTicket} />
          </div>
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ margin: '0 0 -16px 0' }}
        />
      )}
    </Space>
  );
};

export interface MonitorCardProps {
  va?: VehicleAccess;
  type: AccessLogType;
  facility?: Facility;
  revalidate: () => void;
  onPlateNumberUpdated?: VehicleTableProps['onPlateNumberUpdated'];
}

const MonitorCard: FC<MonitorCardProps> = ({
  va,
  type,
  facility: providedFacility,
  revalidate,
  onPlateNumberUpdated,
}) => {
  const axios = useAxios();

  // va.entry & va.exit
  const log = useMemo(() => {
    if (!va) return;
    switch (type) {
      case 'entry':
        return va.entry;
      case 'exit':
        return va.exit;
      case 'internal_entrance':
        return va.internalAccesses
          ?.reverse()
          ?.find((x) => x.type === 'internal_entrance');
      case 'internal_exit':
        return va.internalAccesses
          ?.reverse()
          ?.find((x) => x.type === 'internal_exit');
    }
  }, [va, type]);
  const otherLog = useMemo(() => {
    if (!va) return;
    switch (otherType(type)) {
      case 'entry':
        return va.entry;
      case 'exit':
        return va.exit;
      case 'internal_entrance':
        return va.internalAccesses
          ?.slice()
          .reverse()
          .find((x) => x.type === 'internal_entrance');
      case 'internal_exit':
        return va.internalAccesses
          ?.slice()
          .reverse()
          .find((x) => x.type === 'internal_exit');
    }
  }, [va, type]);

  // va.(entry|exit).facility
  const facility = useMemo(
    () => providedFacility || log?.facility,
    [log?.facility, providedFacility]
  );

  const onDoorClick = useCallback(
    (type: 'open' | 'close') => () => {
      if (!facility) return;
      const text = type === 'open' ? '개방' : '차단';
      const url = `${process.env.REACT_APP_DEVICE_URL}/test/${type}`;

      axios
        .post(url, { id: facility._id })
        .then(() => message.success(`해당 LPR을 한번 ${text}했습니다.`))
        .catch(() => message.error('설정 중 오류가 발생했습니다.'));
    },
    [axios, facility]
  );

  if (!providedFacility && !log) {
    return (
      <VehicleTable
        type={type}
        vehicleAccess={va}
        revalidate={revalidate}
        entryBefore={otherLog?.accessedAt}
        onPlateNumberUpdated={onPlateNumberUpdated}
      />
    );
  }

  return (
    <StyledCard
      bodyStyle={{
        flex: 1,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
      title={
        <Space wrap style={{ width: '100%', justifyContent: 'space-between' }}>
          <Space>
            <Typography.Text>
              {facility?.name || manualName(type)}
            </Typography.Text>

            {facility && (
              <FacilityTag facility={facility} style={{ display: 'block' }} />
            )}

            {!providedFacility && log && (
              <Typography.Text type="secondary">
                {/* 보통은 log.accessedAt 하나만 빠져 있기 어렵지만...?
                 * 출차하지도 않은 차량에 출구 LPR이 사진을 넣어버리는 경우엔 (왜??)
                 * va.exit, va.exit.image 필드만 생기고 나머지 다른 필드들,
                 * va.exit.accessedAt 필드는 없는 경우가 발생할 수 있음...
                 * 정상 절차로는 발생하지 않을 테니 AccessLog['accessedAt'] 타입에
                 * undefined 추가하진 않은 것... format 터질 수 있으니 검사해야 함 */}
                {log.accessedAt
                  ? format(new Date(log.accessedAt), 'yyyy-MM-dd HH:mm:ss')
                  : '-'}
              </Typography.Text>
            )}
          </Space>
          {facility && (
            <Space>
              <span>
                <OverlapButton onClick={onDoorClick('open')}>
                  열림
                </OverlapButton>
                <OverlapButton onClick={onDoorClick('close')}>
                  닫힘
                </OverlapButton>
              </span>
              <RestartButton facilityId={facility._id} />
            </Space>
          )}
        </Space>
      }
      actions={
        facility
          ? [
              <Space
                wrap
                direction="horizontal"
                style={{ justifyContent: 'center' }}
                split={<Divider type="vertical" style={{ margin: 0 }} />}
              >
                <LPRMode facility={facility} revalidate={revalidate} />
                <LPROpen facility={facility} revalidate={revalidate} />
              </Space>,
            ]
          : undefined
      }
    >
      <Cover
        log={log}
        otherLog={otherLog}
        maxCount={providedFacility ? 1 : undefined}
      />
      {providedFacility && <Content va={va} log={log} />}
    </StyledCard>
  );
};

export default MonitorCard;

const SwitchLabel = styled.label`
  font-weight: bold;
  color: #303030;

  [data-theme='dark'] & {
    color: #f0f0f0;
  }
`;

const OverlapButton = styled(Button)`
  z-index: 0;
  &:hover {
    z-index: 1;
  }

  &:not(:first-child) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const StyledCard = styled(Card)`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-card-head {
    padding: 0 16px;
  }
`;
