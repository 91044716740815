import { Space, Tooltip, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { AccessLog } from '@utils/models';
import { format } from 'date-fns';

interface AccessLogTextProps {
  log?: AccessLog;
  dateFormat?: string;
}

const AccessLogText = ({ log, dateFormat }: AccessLogTextProps) => {
  return log ? (
    <Space size={4}>
      {log.accessedAt && (
        <time dateTime={log.accessedAt?.toString()}>
          {format(
            new Date(log.accessedAt),
            dateFormat || 'yyyy-MM-dd HH:mm:ss'
          )}
        </time>
      )}
      {log.confirmed && (
        <Tooltip title="루프아웃 확인됨">
          <Typography.Text type="success" children={<CheckOutlined />} />
        </Tooltip>
      )}
    </Space>
  ) : (
    <Typography.Text type="secondary">-</Typography.Text>
  );
};

export default AccessLogText;
