import { useEffect } from 'react';
import { NesHookProps } from './base';

import { useRecoilValue } from 'recoil';
import { accessTokenState } from '@recoil/auth';

const useConnect = ({ clientRef }: NesHookProps) => {
  const token = useRecoilValue(accessTokenState);

  return useEffect(() => {
    if (!token || !clientRef.current) return;
    const client = clientRef.current;

    client
      .connect({ auth: { headers: { authorization: `Bearer ${token}` } } })
      .catch((err) => console.error('NesContext connection failed', err));

    return () => void (client.id && client.disconnect());
  }, [clientRef, token]);
};

export default useConnect;
