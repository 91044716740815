/** 동호수 정규식 생성기 */
export default function dongho(
  dong: string | null | undefined,
  ho: string | null | undefined
) {
  if (!dong && !ho) return '';
  if (!dong) return `-.*?${ho}.*?`;
  if (!ho) return `.*?${dong}.*?-`;
  return `.*?${dong}.*?-.*?${ho}.*?`;
}
