import { useEffect, useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from '@routes/routes.constants';
import { format } from 'date-fns';

import {
  Breadcrumb,
  Form,
  Input,
  Table,
  Button,
  Select,
  Space,
  DatePicker,
  Typography,
  Checkbox,
  Switch,
  message,
  Popconfirm,
} from 'antd';
import styled from 'styled-components';

import {
  BooleanParam,
  DecodedValueMap,
  NumberParam,
  QueryParamConfig,
  SetQuery,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { stringifyUrl } from 'query-string';
import onSorterChange from '@utils/onSorterChange';

import useSWR from 'swr';
import useAxios from '@hooks/useAxios';
import { useRecoilValue } from 'recoil';
import currentSiteState from '@recoil/current-site';
import { userInfoState } from '@recoil/auth';

import PageHeader from '@components/PageHeader';
import LayoutContentWrapper from '@components/LayoutContentWrapper';
import LayoutContent from '@components/LayoutContent';
import FacilitySelect from '@components/FacilitySelect';
import FriendSelect from '@components/FriendSelect';
import AccessLogText from '@components/AccessLogText';
import ManualEntryModal from './modals/ManualEntryModal';

import moment from 'moment';
import { startOfDay, endOfDay, subDays, set } from 'date-fns';
import type { RangeValue } from 'rc-picker/lib/interface';
import {
  PaginationMeta,
  SeasonTicket,
  VehicleAccess,
  VehicleAccessStatus,
} from '@utils/models';
import { ColumnsType } from 'antd/lib/table';
import RotationTag from './components/RotationTag';
import catchError from '@utils/axios-error';
import dongho from '@utils/dongho';

export const getColumns = (
  withPayments: boolean = false
): ColumnsType<VehicleAccess> => {
  const col = [
    {
      title: '차량번호',
      key: 'vehicle',
      sorter: true,
      render: (data: VehicleAccess) => (
        <Link to={`${PRIVATE_ROUTE.VEHICLE_MANAGE}/info/${data._id}`}>
          {data.vehicle || '미인식'}
        </Link>
      ),
    },
    {
      title: '권종',
      dataIndex: ['payment', 'seasonTicket'],
      sorter: true,
      render: (ticket?: SeasonTicket) =>
        ticket ? (
          <Link to={`${PRIVATE_ROUTE.TICKET_MANAGE}/${ticket._id}`}>
            정기권
          </Link>
        ) : (
          '일반'
        ),
    },
    {
      title: '부제',
      dataIndex: 'rotation',
      sorter: true,
      render: (rotation: VehicleAccess['rotation']) => (
        <RotationTag rotation={rotation} />
      ),
    },
    {
      title: '동호수',
      dataIndex: ['payment', 'seasonTicket'],
      sorter: true,
      render: (ticket?: SeasonTicket) =>
        ticket ? (
          <Link to={`${PRIVATE_ROUTE.TICKET_MANAGE}/${ticket._id}`}>
            {ticket.user?.unit}
          </Link>
        ) : (
          ''
        ),
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (status: VehicleAccess['status']) => VehicleAccessStatus[status],
    },
    {
      title: '입차일시',
      dataIndex: 'entry',
      key: 'entry.accessedAt',
      sorter: true,
      render: (entry: VehicleAccess['entry']) => <AccessLogText log={entry} />,
    },
    {
      title: '출차일시',
      dataIndex: 'exit',
      key: 'exit.accessedAt',
      sorter: true,
      render: (exit: VehicleAccess['exit']) => <AccessLogText log={exit} />,
    },
    {
      title: '입차설비',
      dataIndex: ['entry', 'facility', 'name'],
      key: 'entry.facility',
      sorter: true,
    },
    {
      title: '출차설비',
      dataIndex: ['exit', 'facility', 'name'],
      key: 'exit.facility',
      sorter: true,
    },
  ];
  if (withPayments)
    return [
      ...col,
      ...[
        ['사전정산', 'prepayment'],
        ['출구정산', 'payment'],
        ['후불정산', 'postpayment'],
      ].flatMap((v) => [
        {
          title: `${v[0]} 결제금액`,
          key: `${v[1]}_id.price.total`,
          dataIndex: [`${v[1]}_id`, 'price', 'total'],
          render: (price?: number) => (price === 0 || price) && `${price}원`,
        },
        {
          title: `${v[0]} 결제수단`,
          key: `${v[1]}_id.paymentMethod`,
          dataIndex: [`${v[1]}_id`, 'paymentMethod'],
          width: 20,
        },
        {
          title: `${v[0]} 카드사`,
          key: `${v[1]}_id.cardInfo.description`,
          dataIndex: [`${v[1]}_id`, 'cardInfo', 'description'],
          width: 20,
        },
        {
          title: `${v[0]} 카드번호`,
          key: `${v[1]}_id.cardInfo.number`,
          dataIndex: [`${v[1]}_id`, 'cardInfo', 'number'],
          width: 20,
        },
        {
          title: `${v[0]} 결제코드`,
          key: `${v[1]}_id.cardInfo.transitionCode`,
          dataIndex: [`${v[1]}_id`, 'cardInfo', 'transitionCode'],
          width: 20,
        },
        {
          title: `${v[0]} 승인번호`,
          key: `${v[1]}_id.cardInfo?.approvedNumber`,
          dataIndex: [`${v[1]}_id`, 'cardInfo', 'approvedNumber'],
          width: 20,
        },
        {
          title: `${v[0]} 승인일시`,
          key: `${v[1]}_id.cardInfo.approvedAt`,
          dataIndex: [`${v[1]}_id`, 'cardInfo', 'approvedAt'],
          render: (at: string) =>
            at && format(new Date(at), 'yyyy-MM-dd HH:mm:ss'),
        },
      ]),
    ];
  return col;
};

export default function Log() {
  const axios = useAxios();
  const currentSite = useRecoilValue(currentSiteState)!;
  const { permissionGroup } = useRecoilValue(userInfoState);

  const [isDownloading, setIsDownloading] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const [query, setQuery] = useQueryParams({
    friend: StringParam,
    facilityId: StringParam,
    vehicle: StringParam,
    ticketType: StringParam,
    startRange: NumberParam,
    endRange: NumberParam,
    status: StringParam,
    pageIndex: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 10),
    paymentView: withDefault(BooleanParam, false),
    excludeNull: withDefault(BooleanParam, false),
    closingMode: withDefault(BooleanParam, false),
    dateType: StringParam,
    sort: StringParam,
    unitDong: StringParam,
    unitHo: StringParam,
  });

  const [range, setRange] = useState<RangeValue<moment.Moment>>([
    query.startRange === null
      ? null
      : query.startRange === undefined
      ? moment(startOfDay(new Date()).getTime())
      : moment(query.startRange),
    query.endRange === null
      ? null
      : query.endRange === undefined
      ? moment(endOfDay(new Date()).getTime())
      : moment(query.endRange),
  ]);

  const payload = useMemo(
    () => ({
      siteId: currentSite._id,
      friend: query.friend || undefined,
      facilityId: query.facilityId || undefined,
      vehicle: query.vehicle || undefined,
      status: query.status || undefined,
      ticketType: query.ticketType || undefined,
      [`${query.dateType || 'accessed'}After`]: query.startRange || undefined,
      [`${query.dateType || 'accessed'}Before`]: query.endRange || undefined,
      only: ['friend', 'arcade'].includes(permissionGroup.tag),
      excludeNull: query.excludeNull || undefined,
      populateSeasonTicket: true,
      unit: dongho(query.unitDong, query.unitHo) || undefined,
    }),
    [currentSite, permissionGroup, query]
  );

  const { data, revalidate: revD } = useSWR<{
    vehicleAccesses: VehicleAccess[];
    meta: PaginationMeta;
  }>(
    stringifyUrl({
      url: '/vehicle-accesses',
      query: {
        ...payload,
        count: query.pageSize,
        page: query.pageIndex,
        sort: query.sort || undefined,
      },
    }),
    { refreshInterval: 5000 }
  );

  const { data: appendix, revalidate: revA } = useSWR(
    stringifyUrl({ url: '/vehicle-accesses/appendix', query: payload }),
    { refreshInterval: 5000 }
  );

  const revalidate = useCallback(() => [revD(), revA()], [revD, revA]);

  const [selected, setSelected] = useState<VehicleAccess[]>([]);
  const onClickDelete = useCallback(
    () =>
      Promise.all(
        selected.map(({ _id }) => axios.delete(`/vehicle-accesses/${_id}`))
      )
        .then(() => [
          revalidate(),
          message.success('선택한 차량 기록이 성공적으로 삭제되었습니다.'),
        ])
        .catch(catchError('차량 기록 삭제 중 오류가 발생했습니다.'))
        .finally(() => setSelected([])),

    [axios, revalidate, selected, setSelected]
  );

  const isOwnerOrFriend = useMemo(
    () => ['friend', 'owner', 'arcade'].includes(permissionGroup.tag),
    [permissionGroup]
  );

  const [filterForm] = Form.useForm();
  useEffect(() => filterForm.submit(), [filterForm]);

  const onClickDownloadExcel = async () => {
    if (isDownloading) return;
    setIsDownloading(true);

    const { data } = await axios.get(
      stringifyUrl({
        url: '/vehicle-accesses/excel',
        query: payload,
      }),
      { responseType: 'arraybuffer' }
    );
    const blob = new Blob([data]);
    const src = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', src);
    link.setAttribute('download', '차량_관리_검색결과.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  const setClosing = useCallback(
    (d: Date) =>
      set(d, {
        hours: currentSite.closingHour,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
    [currentSite.closingHour]
  );

  const start = useMemo(
    () =>
      query.closingMode ? (d: Date) => setClosing(subDays(d, 1)) : startOfDay,
    [query.closingMode, setClosing]
  );

  const end = useMemo(
    () => (query.closingMode ? (d: Date) => setClosing(d) : endOfDay),
    [query.closingMode, setClosing]
  );

  const closingHourText = useMemo(
    () => moment(currentSite.closingHour || '', 'H').format('HH:00'),
    [currentSite.closingHour]
  );

  return (
    <>
      <LayoutContentWrapper>
        <PageHeader>
          <Breadcrumb>
            <Breadcrumb.Item>차량 관리</Breadcrumb.Item>
            <Breadcrumb.Item>차량 관리</Breadcrumb.Item>
          </Breadcrumb>
        </PageHeader>
        <LayoutContent>
          <ToolkitWrap
            style={{ justifyContent: 'space-between', rowGap: '10px' }}
          >
            <Space>
              {selected.length > 0 ? (
                <>
                  <Popconfirm
                    onConfirm={onClickDelete}
                    title={`선택한 ${selected.length}개의 차량 기록을 정말 삭제하시겠습니까?`}
                  >
                    <Button type="primary" danger>
                      삭제
                    </Button>
                  </Popconfirm>

                  <Typography.Text style={{ marginLeft: '1rem' }}>
                    {selected.length}개 선택됨
                  </Typography.Text>
                </>
              ) : (
                <>
                  {!isOwnerOrFriend && (
                    <Button
                      type="primary"
                      onClick={() =>
                        setIsModalOpened((prevState) => !prevState)
                      }
                    >
                      수동 입차
                    </Button>
                  )}
                  <Button
                    type="primary"
                    ghost
                    loading={isDownloading}
                    onClick={onClickDownloadExcel}
                  >
                    엑셀 다운로드
                  </Button>
                </>
              )}
            </Space>

            <Form
              layout="inline"
              form={filterForm}
              onFinish={({
                facilityId,
                friend,
                ticketType,
                status,
                vehicle,
                paymentView,
                excludeNull,
                dateType,
                unitDong,
                unitHo,
              }) => {
                const values = {
                  friend,
                  facilityId,
                  ticketType,
                  status,
                  vehicle,
                  startRange:
                    ((r) => r && start(r.toDate()).getTime())(
                      range?.[query.closingMode ? 1 : 0]
                    ) || null,
                  endRange:
                    (range?.[1] && end(range[1].toDate()).getTime()) || null,
                  paymentView,
                  excludeNull,
                  dateType,
                  unitDong,
                  unitHo,
                };

                const hasChanged = Object.entries(values).some(
                  ([k, v]) => query[k as keyof typeof query] !== v
                );

                setQuery(
                  { ...values, ...(hasChanged && { pageIndex: 1 }) },
                  'replaceIn'
                );
              }}
              initialValues={{
                facilityId: query.facilityId,
                ticketType: query.ticketType,
                status: query.status,
                vehicle: query.vehicle,
                friend: query.friend,
                excludeNull: query.excludeNull,
                paymentView: query.paymentView,
                dateType: query.dateType,
                unitDong: query.unitDong,
                unitHo: query.unitHo,
              }}
            >
              <Form.Item name="paymentView" valuePropName="checked">
                <Checkbox style={{ display: 'flex', alignItems: 'center' }}>
                  결제내역 보기
                </Checkbox>
              </Form.Item>
              <Form.Item name="excludeNull" valuePropName="checked">
                <Checkbox style={{ display: 'flex', alignItems: 'center' }}>
                  미인식차량 제외
                </Checkbox>
              </Form.Item>

              {!isOwnerOrFriend && (
                <Form.Item name="friend">
                  <FriendSelect documentIdAsValue emptyText="모든 입주사" />
                </Form.Item>
              )}
              {!isOwnerOrFriend && (
                <Form.Item name="facilityId">
                  <FacilitySelect showSelectAll allowClear />
                </Form.Item>
              )}
              <Form.Item name="ticketType">
                <Select allowClear placeholder="권종" style={{ width: 85 }}>
                  <Select.Option value="">모두</Select.Option>
                  <Select.Option value="ticket">정기권</Select.Option>
                  <Select.Option value="default">일반</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="status">
                <Select allowClear placeholder="상태" style={{ width: 125 }}>
                  <Select.Option value="">모두</Select.Option>
                  {Object.entries(VehicleAccessStatus).map(([key, value]) => (
                    <Select.Option value={key} key={key}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <LeftFormItem name="dateType">
                <Select placeholder="일시" style={{ width: 70 }} allowClear>
                  <Select.Option value="">모두</Select.Option>
                  <Select.Option value="entry">입차</Select.Option>
                  <Select.Option value="exit">출차</Select.Option>
                </Select>
              </LeftFormItem>

              <RightFormItem>
                {query.closingMode ? (
                  <DatePicker
                    value={range?.[1]}
                    onChange={(v) => setRange([v, v])}
                  />
                ) : (
                  <DatePicker.RangePicker
                    value={range}
                    onChange={(v) => setRange(v)}
                  />
                )}
              </RightFormItem>

              <Form.Item
                style={{
                  display: currentSite.closingHour === 0 ? 'none' : undefined,
                }}
              >
                <Switch
                  title={closingHourText}
                  checked={query.closingMode}
                  checkedChildren="마감기준"
                  unCheckedChildren="자정기준"
                  onChange={(v) => setQuery({ closingMode: v }, 'replaceIn')}
                />
              </Form.Item>

              <Form.Item name="unitDong">
                <Input placeholder="동" style={{ width: 80 }} />
              </Form.Item>
              <Form.Item name="unitHo">
                <Input placeholder="호수" style={{ width: 80 }} />
              </Form.Item>

              <Form.Item name="vehicle">
                <Input placeholder="차량번호" style={{ width: 140 }} />
              </Form.Item>

              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form>
          </ToolkitWrap>
          {currentSite && (
            <LogTable
              data={data}
              query={query}
              setQuery={setQuery}
              selected={selected}
              setSelected={setSelected}
              paymentView={query.paymentView}
            />
          )}
          <Typography.Text>{appendix}</Typography.Text>
        </LayoutContent>
      </LayoutContentWrapper>
      <ManualEntryModal
        visible={isModalOpened}
        setVisible={setIsModalOpened}
        revalidate={revalidate}
        goPage={false}
      />
    </>
  );
}

type LogTableQuery = {
  pageIndex: QueryParamConfig<number | null | undefined, number>;
  pageSize: QueryParamConfig<number | null | undefined, number>;
};

export const LogTable = ({
  data,
  query,
  setQuery,
  selected,
  setSelected,
  paymentView = false,
}: {
  data:
    | {
        vehicleAccesses: VehicleAccess[];
        meta: PaginationMeta;
      }
    | undefined;
  query: DecodedValueMap<LogTableQuery>;
  setQuery: SetQuery<LogTableQuery>;
  selected: VehicleAccess[];
  setSelected: React.Dispatch<React.SetStateAction<VehicleAccess[]>>;
  paymentView?: boolean;
}) => {
  return (
    <Table
      size="small"
      loading={!data}
      dataSource={data?.vehicleAccesses}
      rowKey={(record) => record._id}
      columns={getColumns(paymentView)}
      pagination={{
        size: 'default',
        total: data?.meta?.totalCount,
        pageSize: query.pageSize,
        defaultCurrent: query.pageIndex,
        current: query.pageIndex,
        showTotal: (total) => `총 ${total}개`,
        onChange: (page, newPageSize) => {
          setQuery(
            {
              pageIndex: page,
              pageSize: newPageSize || query.pageSize,
            },
            'replaceIn'
          );
        },
      }}
      onChange={onSorterChange(setQuery)}
      rowSelection={{
        type: 'checkbox',
        onChange: (_, rows) => setSelected(rows),
        selectedRowKeys: selected.map((v) => v._id),
      }}
    />
  );
};

export const LeftFormItem = styled(Form.Item)`
  margin-right: 0 !important;

  .ant-select-open,
  .ant-select-focused {
    z-index: 1;
  }

  .ant-select-selector {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    &:hover {
      z-index: 1;
    }
  }
`;
export const RightFormItem = styled(Form.Item)`
  margin-left: -1px !important;

  .ant-picker {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;

export const ToolkitWrap = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin-bottom: 8px;
  }

  & .ant-form-item {
    margin-bottom: 8px;
  }
`;
