import { Select } from 'antd';
import useSWR from 'swr';
import { useRecoilValue } from 'recoil';
import currentSiteState from '@recoil/current-site';
import { SelectProps } from 'antd/lib/select';
import { Facility } from '@utils/models';

interface FacilitySelectProps extends SelectProps<any> {
  showSelectAll?: boolean;
  kind?: 'entrance_lpr' | 'exit_lpr' | 'entrance_lpr|exit_lpr';
}

export default ({
  showSelectAll,
  kind = 'entrance_lpr|exit_lpr',
  ...props
}: FacilitySelectProps) => {
  const currentSite = useRecoilValue(currentSiteState)!;
  const { data } = useSWR<{ facilities: Facility[] }>(
    `/facilities?siteId=${currentSite._id}&kind=${kind}`
  );
  return (
    <Select placeholder="설비 선택" style={{ width: 100 }} {...props}>
      {showSelectAll && <Select.Option value="">모두</Select.Option>}
      {data?.facilities.map((facility) => (
        <Select.Option key={facility._id} value={facility._id}>
          {facility.name}
        </Select.Option>
      ))}
    </Select>
  );
};
