import { Handler } from '@hapi/nes';
import { NesHookProps } from '../base';
import { Facility } from '@utils/models';

import useOpener from '../opener';
import useForwardingLink from '../forwarding_link';

import { useCallback, useEffect } from 'react';
import { Button, notification } from 'antd';
import NarrowDescriptions from '@contexts/components/NarrowDescriptions';

import queryString from 'query-string';
import { PRIVATE_ROUTE } from '@routes/routes.constants';

// 설비 VoC 콜 알림 ('credit_call')
const channel = '/nes/credit_call';

const useCreditCall = ({ clientRef }: NesHookProps) => {
  const open = useOpener();
  const push = useForwardingLink();

  const subscribe: Handler = useCallback(
    (facility: Facility) => {
      const now = Date.now();
      const facilityId = facility._id;
      const key = now.toString() + facilityId;

      open({
        key,
        time: now,
        message: 'VoC 콜 알림',
        description: (
          <NarrowDescriptions>
            <NarrowDescriptions.Item label="주차장">
              {facility.site.name}
            </NarrowDescriptions.Item>
            <NarrowDescriptions.Item label="설비">
              {facility.name}
            </NarrowDescriptions.Item>
          </NarrowDescriptions>
        ),
        actions: (
          <Button
            type="primary"
            onClick={() => {
              push({
                type: '설비',
                site: facility.site,
                path: `${PRIVATE_ROUTE.VOC}?facilityId=${facilityId}`,
              });

              window.open(
                queryString.stringifyUrl({
                  url: PRIVATE_ROUTE.VEHICLE_CONTROL_MONITOR,
                  query: { facilityId },
                }),
                'VoC_VehicleControlMonitor',
                `width=${window.innerWidth},height=${window.innerHeight}`
              );

              notification.close(key);
            }}
          >
            VoC 접수
          </Button>
        ),
      });
    },
    [open, push]
  );

  return useEffect(() => {
    if (!clientRef.current) return;
    const client = clientRef.current;
    client.subscribe(channel, subscribe);
    return () => void client.unsubscribe(channel, subscribe);
  }, [clientRef, subscribe]);
};

export default useCreditCall;
