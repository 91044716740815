import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Card, Table, Descriptions, Alert } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { renderDate } from '@utils';
import { VehicleAccess } from '@utils/models';
import Alpha from '@pages/SiteManage/components/Alpha';

const typed = (type: string, arr: any[]) =>
  arr?.map((v: any) => ({ ...v, type })) ?? [];

interface Props {
  className?: string;
  va?: VehicleAccess;
  small?: boolean;
  cardless?: boolean;
}

const emptyPayment: VehicleAccess['payment'] = {
  logs: [],
  oldLogs: [],
  discountCouponList: [],
  cash: 0,
  cost: 0,
  discountCost: 0,
  totalCost: 0,
  costWithoutCoupon: 0,
  costExceeded: false,
  discountExceeded: false,
};

const PaymentLogs: React.FC<Props> = ({ className, va, small, cardless }) => {
  const p = useMemo(() => va?.payment ?? emptyPayment, [va?.payment]);

  const newLogs = useMemo(() => typed('new', p?.logs), [p?.logs]);
  const oldLogs = useMemo(() => typed('old', p?.oldLogs), [p?.oldLogs]);

  const renderPlan = (v: any) => v && <Alpha data={v} popover small={small} />;
  const renderPrice = (v: number) => `${v?.toLocaleString?.() || '0'}원`;

  const plan = { align: 'center', render: renderPlan } as const;
  const date = {
    align: 'center',
    width: small ? 160 : 180,
    render: (date: string) => renderDate(date),
  } as const;

  const columns: ColumnsType<any> = [
    { title: '금액', dataIndex: 'cost', render: renderPrice, align: 'right' },
    { title: '구간수', dataIndex: 'segment', align: 'center' },
    { title: '이름', dataIndex: 'name' },
    { title: '요금제', dataIndex: 'parkingCostPlan', ...plan },
    { title: '시작', dataIndex: 'startDate', ...date },
    { title: '끝', dataIndex: 'endDate', ...date },
  ];

  const content = (
    <Container className={className}>
      <SpecialTable
        loading={!va}
        size={small ? 'small' : undefined}
        pagination={false}
        dataSource={[...oldLogs, ...newLogs]}
        rowKey={(log: any) => JSON.stringify(log)}
        onRow={(log: any) => ({ className: log.type })}
        columns={columns}
      />

      <SpecialDescriptions
        bordered
        size={small ? 'small' : undefined}
        column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 3 }}
        contentStyle={{ textAlign: 'right' }}
      >
        <Descriptions.Item label="할인 전 금액">
          {renderPrice(p?.cost)}
        </Descriptions.Item>
        <Descriptions.Item label="할인금액">
          {renderPrice(p?.discountCost)}
        </Descriptions.Item>
        <Descriptions.Item label="최종금액">
          {renderPrice(p?.totalCost)}
        </Descriptions.Item>
      </SpecialDescriptions>

      {p?.costExceeded && (
        <>
          <br />
          <Alert
            showIcon
            type="warning"
            message={`주차요금 정책의 최대 금액 제한을 초과했습니다. 원래 요금은 ${renderPrice(
              p?.logs?.reduce(
                (total: number, log: any) => total + log.cost,
                0
              ) || 0
            )}입니다.`}
          />
        </>
      )}

      {p?.discountExceeded && (
        <>
          <br />
          <Alert
            showIcon
            type="warning"
            message="주차요금 정책의 최대 할인 금액을 초과했습니다. 이대로 정산하면 할인권의 잔액이 환불되지 않습니다."
          />
        </>
      )}
    </Container>
  );

  return cardless ? (
    content
  ) : (
    <Card>
      <Title>요금 내역</Title>
      {content}
    </Card>
  );
};

export default PaymentLogs;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16.9px;
`;

const SpecialTable = styled(Table)`
  flex: 1;

  border: 1px solid rgb(240, 240, 240);
  border-bottom: none;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  [data-theme='dark'] & {
    border-color: #303030;
  }

  & .ant-table-row.old {
    background-color: #fafafa;
    [data-theme='dark'] & {
      background-color: #262626;
    }
  }
`;

const SpecialDescriptions = styled(Descriptions)`
  & > .ant-descriptions-view {
    /* border-top: none !important; */
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
