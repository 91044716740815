import React, { useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import currentSiteState from '@recoil/current-site';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { useHistory } from 'react-router-dom';
import { PRIVATE_ROUTE } from '../../../routes/routes.constants';

interface Props {
  seasonTicket: any;
}

// LG CNS 커스텀 요청사항
// feat: 주차관제 모니터링 정기권 정보 표시 (9ae5f50)

const useEnableFlag = () => {
  const site = useRecoilValue(currentSiteState)!;
  const [flag] = useQueryParam('cns_ticket', BooleanParam);
  return useMemo(() => flag || /CNS/i.test(site._id), [site, flag]);
};

const SeasonTicketSpan: React.FC<Props> = ({ seasonTicket }) => {
  const history = useHistory();
  const isCnsCustom = useEnableFlag();

  const linkProps = useMemo(
    () => ({
      style: {
        cursor: 'pointer',
        display: seasonTicket ? undefined : 'none',
      },
      onClick: () =>
        seasonTicket &&
        history.push(`${PRIVATE_ROUTE.TICKET_MANAGE}/${seasonTicket._id}`),
    }),
    [history, seasonTicket]
  );

  return isCnsCustom ? (
    // CNS 주차장에서는 아래 줄에 크게 표시
    <div style={{ fontSize: '1.25rem', marginTop: '0.5rem' }}>
      {seasonTicket ? (
        <span {...linkProps}>
          정기권: {seasonTicket?.user?.name ?? '(이름 없음)'}/
          {seasonTicket?.user.department ?? '(부서 없음)'}
        </span>
      ) : (
        <span style={{ color: 'lightgrey' }}>정기권 정보 없음</span>
      )}
    </div>
  ) : (
    // 다른 주차장에서는 시간 옆에 작게 표시
    <span {...linkProps}>
      <span style={{ marginLeft: '0.6rem' }}>
        {seasonTicket?.user?.name ?? ''}
      </span>
      <span style={{ marginLeft: '0.6rem' }}>
        {seasonTicket?.user.department ?? ''}
      </span>
    </span>
  );
};

export default SeasonTicketSpan;
