import { Handler } from '@hapi/nes';

import { NesHookProps } from '../base';
import { VehicleAccess, VehicleAccessReservation } from '@utils/models';

import useOpener from '../opener';
import useForwardingLink from '../forwarding_link';

import { useCallback, useEffect } from 'react';
import { Typography } from 'antd';

import { PRIVATE_ROUTE } from '@routes/routes.constants';

// 방문예약 알림 ('reservation')
const channel = '/nes/reservation';

type NesReservation = {
  va: VehicleAccess;
  reservation: VehicleAccessReservation;
};

const useReservation = ({ clientRef }: NesHookProps) => {
  const open = useOpener();
  const push = useForwardingLink();

  const subscribe: Handler = useCallback(
    ({ va, reservation }: NesReservation) => {
      const accessLog = va.exit || va.entry;
      const key = va._id + accessLog?.accessedAt;

      open({
        key,
        time: accessLog?.accessedAt,
        message: '방문예약 알림',
        description: (
          <>
            {reservation?.user?.name && (
              <>
                <Typography.Link
                  onClick={() =>
                    push({
                      type: '방문예약',
                      site: reservation.site,
                      path: `${PRIVATE_ROUTE.RESERVATION_MANAGE}/${reservation._id}`,
                    })
                  }
                >
                  {reservation.user.name}
                </Typography.Link>
                님의{' '}
              </>
            )}
            <Typography.Link
              onClick={() =>
                push({
                  type: '차량',
                  site: reservation?.site,
                  path: `${PRIVATE_ROUTE.VEHICLE_MANAGE}/info/${va._id}`,
                })
              }
            >
              {va.vehicle}
            </Typography.Link>{' '}
            차량이 {accessLog === va.exit ? '출차' : '입차'}했습니다.
          </>
        ),
      });
    },
    [open, push]
  );

  return useEffect(() => {
    if (!clientRef.current) return;
    const client = clientRef.current;
    client.subscribe(channel, subscribe);
    return () => void client.unsubscribe(channel, subscribe);
  }, [clientRef, subscribe]);
};

export default useReservation;
