import { atom } from 'recoil';

export enum THEMES {
  LIGHT,
  DARK,
}

const themeState = atom<THEMES>({
  key: 'theme',
  default: THEMES.LIGHT,
});

export default themeState;
