import React from 'react';

export interface RTSPPlayerProps {
  url: string;
}

function RTSPPlayer({ url }: RTSPPlayerProps) {
  return (
    <div
      style={{
        position: 'relative',
        minHeight: 300,
        width: '100%',
      }}
    >
      <iframe
        title="cam"
        id="fp_embed_player"
        src={`http://ec2-3-34-42-148.ap-northeast-2.compute.amazonaws.com:9091/embed_player?urlServer=ws://ec2-3-34-42-148.ap-northeast-2.compute.amazonaws.com:8080&streamName=${encodeURIComponent(
          url
        )}&mediaProviders=MSE,WSPlayer`}
        marginWidth={0}
        marginHeight={0}
        frameBorder={0}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
        scrolling="no"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default React.memo(RTSPPlayer);
