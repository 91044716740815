import React from 'react';
import loadable from '@loadable/component';
import { PUBLIC_ROUTE, PRIVATE_ROUTE } from './routes.constants';
import { Redirect } from 'react-router';

export interface IRoute {
  exact?: boolean;
  path?: string;
  component?: any;
}

export const publicRoutes: Array<IRoute> = [
  {
    path: PUBLIC_ROUTE.ROOT,
    component: () => <Redirect to="/main" />,
  },
  {
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import('@pages/Auth/Login')),
  },
  {
    path: PUBLIC_ROUTE.LOGIN_MOBILE,
    component: loadable(() => import('@pages/LoginPages/Login')),
  },
  {
    path: PUBLIC_ROUTE.FRIEND_SIGNUP,
    component: loadable(() => import('@pages/FriendSignUpPage/FriendSignUp')),
  },
  {
    path: PUBLIC_ROUTE.ADMIN_SIGNUP,
    component: loadable(() => import('@pages/AdminSignUpPage/AdminSignUp')),
  },
  {
    path: PUBLIC_ROUTE.RECOVER,
    component: loadable(() => import('@pages/Recover/Recover')),
  },
  {
    path: `${PUBLIC_ROUTE.RESET_PASSWORD}/:token`,
    component: loadable(() => import('@pages/Recover/ResetPassword')),
  },
  {
    path: PUBLIC_ROUTE.PAYMENT_SUCCESS,
    component: loadable(() => import('@pages/PaymentResult/PaymentSuccess')),
  },
  {
    path: PUBLIC_ROUTE.PAYMENT_CANCEL,
    component: loadable(() => import('@pages/PaymentResult/PaymentCancel')),
  },
  {
    path: `${PUBLIC_ROUTE.QRCODE_SHARE}/:id`,
    component: loadable(() => import('@pages/Friend/QRCodeShare')),
  },
];

export const privateRoutes: Array<IRoute> = [
  {
    path: PRIVATE_ROUTE.INTEGRATED_CONTROL_MONITOR,
    component: loadable(() => import('@pages/ControlTower')),
  },
  {
    path: PRIVATE_ROUTE.PARKING_CONTROL_MONITOR,
    component: loadable(() => import('@pages/Parking/Monitor')),
  },
  {
    path: PRIVATE_ROUTE.VEHICLE_CONTROL_MONITOR,
    component: loadable(() => import('@pages/Parking/VehicleMonitor')),
  },
  {
    path: PRIVATE_ROUTE.DISCOUNT_APPLY,
    component: loadable(() => import('@pages/DiscountApply/DiscountApply')),
  },
  {
    path: PRIVATE_ROUTE.DISCOUNT_PURCHASE,
    component: loadable(
      () => import('@pages/DiscountPurchase/DiscountPurchase')
    ),
  },
  {
    path: PRIVATE_ROUTE.MAIN,
    component: loadable(() => import('pages/Main/Main')),
  },
  {
    path: PRIVATE_ROUTE.USER_ADMIN_MANAGE,

    component: loadable(() => import('@pages/AdminManage/AdminUserManage')),
  },
  {
    path: PRIVATE_ROUTE.USER_GENERAL_MANAGE,

    component: loadable(() => import('@pages/AdminManage/GeneralUserManage')),
  },
  {
    path: `${PRIVATE_ROUTE.USER_ADMIN_MANAGE}/view/:adminId`,
    component: loadable(
      () => import('@pages/AdminInformation/AdminInformation')
    ),
  },
  {
    path: `${PRIVATE_ROUTE.USER_GENERAL_MANAGE}/:_id`,
    component: loadable(
      () => import('@pages/GeneralUserInformation/GeneralUser')
    ),
  },
  {
    path: `${PRIVATE_ROUTE.USER_GENERAL_MANAGE}/view/:_id`,
    component: loadable(
      () => import('@pages/GeneralUserInformation/GeneralUserInformation')
    ),
  },
  {
    path: PRIVATE_ROUTE.PARKING_LOT_MANAGE,
    component: loadable(() => import('@pages/SiteManage')),
  },
  {
    path: `${PRIVATE_ROUTE.PARKING_LOT_MANAGE}/view/:siteId`,
    component: loadable(() => import('@pages/SiteManage/SiteView')),
  },
  {
    path: `${PRIVATE_ROUTE.PARKING_LOT_MANAGE}/create`,
    component: loadable(() => import('@pages/SiteManage/SiteCreate')),
  },
  {
    path: `${PRIVATE_ROUTE.PARKING_LOT_MANAGE}/status`,
    component: loadable(() => import('@pages/SiteManage/SiteStatus')),
  },
  {
    path: `${PRIVATE_ROUTE.PARKING_LOT_POLICY}`,
    component: loadable(() => import('@pages/SiteManage/SitePolicy')),
  },
  {
    path: `${PRIVATE_ROUTE.DISCOUNT_COUPON_POLICY}`,
    component: loadable(() => import('@pages/SiteDiscount')),
  },
  {
    path: `${PRIVATE_ROUTE.DISCOUNT_COUPON_KEYS}`,
    component: loadable(() => import('@pages/SiteDiscount/SiteCoupons')),
  },
  {
    path: PRIVATE_ROUTE.FACILITY_MANAGE,
    component: loadable(() => import('@pages/SiteManage/FacilityManage')),
  },
  {
    path: `${PRIVATE_ROUTE.FACILITY_MANAGE}/create`,
    component: loadable(() => import('@pages/SiteManage/FacilitySubmit')),
  },
  {
    path: `${PRIVATE_ROUTE.FACILITY_MANAGE}/view/:facilityId`,
    component: loadable(() => import('@pages/SiteManage/FacilityView')),
  },
  {
    path: PRIVATE_ROUTE.FRIEND_MANAGE,
    component: loadable(() => import('@pages/Friend/FriendManage')),
  },
  {
    path: `${PRIVATE_ROUTE.FRIEND_MANAGE}/:friendId`,
    component: loadable(() => import('@pages/Friend/FriendInformation')),
  },
  {
    path: PRIVATE_ROUTE.FRIEND_TICKET,
    component: loadable(() => import('@pages/Friend/FriendTicket')),
  },
  {
    /** @deprecated TICKET_MANAGE */
    path: PRIVATE_ROUTE.FRIEND_TICKET_USERS,
    component: loadable(() => import('@pages/Ticket/TicketManage')),
  },
  {
    /** @deprecated TICKET_MANAGE */
    path: `${PRIVATE_ROUTE.FRIEND_TICKET_USERS}/:ticketId`,
    component: loadable(() => import('@pages/Ticket/TicketInfo')),
  },
  {
    path: PRIVATE_ROUTE.FRIEND_DISCOUNT_CHARGE,
    component: loadable(() => import('@pages/Friend/FriendCharge')),
  },
  {
    path: PRIVATE_ROUTE.FRIEND_DISCOUNT_QRCODE,
    component: loadable(() => import('@pages/Friend/FriendQRCode')),
  },
  {
    path: `${PRIVATE_ROUTE.FRIEND_DISCOUNT_QRCODE}/view`,
    component: loadable(() => import('@pages/Friend/FriendQRCodeView')),
  },
  {
    path: PRIVATE_ROUTE.FRIEND_DISCOUNT_APPLICATION,
    component: loadable(() => import('@pages/Friend/FriendCouponApplyHistory')),
  },
  {
    path: PRIVATE_ROUTE.FRIEND_DISCOUNT_LINK,
    component: loadable(() => import('@pages/Friend/FriendDiscountLink')),
  },
  {
    path: PRIVATE_ROUTE.FRIEND_PARKING_LOT,
    component: loadable(() => import('@pages/Friend/FriendDiscountParkingLot')),
  },
  {
    path: PRIVATE_ROUTE.FRIEND_NOTICE,
    component: loadable(() => import('@pages/Friend/FriendNotice')),
  },
  {
    path: PRIVATE_ROUTE.VEHICLE_MANAGE,
    component: loadable(() => import('@pages/Parking/Log')),
  },
  {
    path: PRIVATE_ROUTE.VEHICLE_UNRECOGNIZED,
    component: loadable(() => import('@pages/Parking/UnrecognizedVehicles')),
  },
  {
    path: `${PRIVATE_ROUTE.VEHICLE_MANAGE}/info/:vaId`,
    component: loadable(() => import('@pages/Parking/VehicleInformation')),
  },
  {
    path: PRIVATE_ROUTE.VEHICLE_LONG_TERM,
    component: loadable(() => import('@pages/Parking/VehicleLongTerm')),
  },
  {
    path: PRIVATE_ROUTE.TICKET_MANAGE,
    component: loadable(() => import('@pages/Ticket/TicketManage')),
  },
  {
    path: PRIVATE_ROUTE.TICKET_WAITLIST,
    component: loadable(() => import('@pages/Ticket/TicketWaitlist')),
  },
  {
    path: PRIVATE_ROUTE.TICKET_EXTENSION,
    component: loadable(() => import('@pages/Ticket/TicketExtension')),
  },
  {
    path: '/payment-ticket-group',
    component: loadable(() => import('@pages/Payment/TicketGroupPayment')),
  },
  {
    path: `${PRIVATE_ROUTE.TICKET_MANAGE}/:ticketId`,
    component: loadable(() => import('@pages/Ticket/TicketInfo')),
  },
  {
    path: PRIVATE_ROUTE.PAYMENT_VEHICLE,
    component: loadable(() => import('@pages/Payment/VehiclePayment')),
  },
  {
    path: `${PRIVATE_ROUTE.PAYMENT_VEHICLE}/:paymentId`,
    component: loadable(
      () => import('@pages/Payment/VehiclePaymentInformation')
    ),
  },
  {
    path: PRIVATE_ROUTE.PAYMENT_COUPON,
    component: loadable(() => import('@pages/Payment/CouponPayment')),
  },
  {
    path: PRIVATE_ROUTE.PAYMENT_TICKET,
    component: loadable(() => import('@pages/Payment/TicketPayment')),
  },
  {
    path: PRIVATE_ROUTE.POSTPAY,
    component: loadable(() => import('@pages/PostPayManage/PostPay')),
  },
  {
    path: PRIVATE_ROUTE.RESERVATION_MANAGE,
    component: loadable(() => import('@pages/Reservation')),
  },
  {
    component: loadable(() => import('@pages/Reservation/ReservationInfo')),
    path: `${PRIVATE_ROUTE.RESERVATION_MANAGE}/:reservationId`,
  },
  {
    path: PRIVATE_ROUTE.REPORT_CLOSINGS,
    component: loadable(() => import('@pages/Report/Closings')),
  },
  {
    path: `${PRIVATE_ROUTE.REPORT_CLOSINGS}/:reportId`,
    component: loadable(() => import('@pages/Report/ClosingInformation')),
  },
  {
    path: PRIVATE_ROUTE.REPORT_SALES,
    component: loadable(() => import('@pages/Report/Sales')),
  },
  {
    path: `${PRIVATE_ROUTE.REPORT_SALES}/:reportId`,
    component: loadable(() => import('@pages/Report/SalesInformation')),
  },
  {
    path: PRIVATE_ROUTE.REPORT_POSTPAY,
    component: loadable(() => import('@pages/Report/Postpay')),
  },
  {
    path: `${PRIVATE_ROUTE.REPORT_POSTPAY}/:reportId`,
    component: loadable(() => import('@pages/Report/PostpayInformation')),
  },
  {
    path: `/report-vehicle`,
    component: loadable(() => import('@pages/Report/Vehicle')),
  },
  {
    path: PRIVATE_ROUTE.VOC,
    component: loadable(() => import('@pages/Voc/Voc')),
  },
  {
    path: PRIVATE_ROUTE.VOC_LIST,
    component: loadable(() => import('@pages/Voc/VocList')),
  },
  {
    path: PRIVATE_ROUTE.NOTICE_MANAGE,
    component: loadable(() => import('@pages/Notice')),
  },
  {
    path: `${PRIVATE_ROUTE.NOTICE_MANAGE}/create`,
    component: loadable(() => import('@pages/Notice/NoticeCreate')),
  },
  {
    path: `${PRIVATE_ROUTE.NOTICE_MANAGE}/view/:noticeId`,
    component: loadable(() => import('@pages/Notice/NoticeView')),
  },
  {
    path: PRIVATE_ROUTE.FEEDBACK_MANAGE,
    component: loadable(() => import('@pages/Feedback')),
  },
  {
    path: `${PRIVATE_ROUTE.FEEDBACK_MANAGE}/view/:_id`,
    component: loadable(() => import('@pages/Feedback/FeedbackView')),
  },
  {
    path: `${PRIVATE_ROUTE.FEEDBACK_MANAGE}/create`,
    component: loadable(() => import('@pages/Feedback/FeedbackCreate')),
  },
  {
    path: PRIVATE_ROUTE.AFTER_SERVICE_MANAGE,
    component: loadable(() => import('@pages/AfterService')),
  },
  {
    path: PRIVATE_ROUTE.SETTING_PUSH,
    component: loadable(() => import('@pages/Setting/SettingPush')),
  },
  {
    path: PRIVATE_ROUTE.PROFILE,
    component: loadable(() => import('@pages/Profile/Profile')),
  },
  {
    path: `${PRIVATE_ROUTE.PROFILE}/password`,
    component: loadable(() => import('@pages/Profile/ChangePassword')),
  },
  {
    path: PRIVATE_ROUTE.REAL_TIME_SALES,
    component: loadable(() => import('@pages/Report/RealTimeSales')),
  },
  {
    path: PRIVATE_ROUTE.TICKET_RAFFLE,
    component: loadable(() => import('@pages/TicketRaffle/TicketRaffle')),
  },
  {
    path: `${PRIVATE_ROUTE.TICKET_RAFFLE}/:raffleId`,
    component: loadable(() => import('@pages/TicketRaffle/TicketRaffleInfo')),
  },
  {
    path: `${PRIVATE_ROUTE.CLOUDWATCH}`,
    component: loadable(() => import('@pages/CloudWatch/DescribeLogGroups')),
  },
  {
    path: `${PRIVATE_ROUTE.CLOUDWATCH}/:events`,
    component: loadable(() => import('@pages/CloudWatch/FilterLogEvents')),
  },
  {
    path: PRIVATE_ROUTE.MANNED_SETTLEMENT,
    component: loadable(() => import('@pages/Parking/MannedSettlement')),
  },
  {
    path: PRIVATE_ROUTE.TOWER_MANAGE,
    component: loadable(() => import('@pages/Tower/TowerManage')),
  },
  {
    path: '/throw-error',
    component: loadable(() => import('@pages/ThrowError')),
  },
];
