import { FC, useCallback, useState } from 'react';
import { Button, ButtonProps, message, Modal } from 'antd';
import useAxios from '@hooks/useAxios';
import { VehicleAccess } from '@utils/models';

interface Props extends ButtonProps {
  va: VehicleAccess;
  revalidate: () => void;
}

const FreeExitButton: FC<Props> = ({ va, revalidate, ...props }) => {
  const axios = useAxios();
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    Modal.confirm({
      content: '해당 차량 기록을 무료출차 하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk: async () => {
        if (!va) return;
        if (!va.exit) return message.error('출차처리 후 진행해주세요.');

        setLoading(true);
        try {
          await axios.post(`/vehicle-accesses/${va._id}/free-exit`);
          if (va.exit.facility) {
            await axios.post(`${process.env.REACT_APP_DEVICE_URL}/test/open`, {
              id:
                typeof va.exit.facility === 'string'
                  ? va.exit.facility
                  : va.exit.facility?._id,
            });
          }

          message.success('무료출차 처리되었습니다.');
          revalidate();
        } catch (err: any) {
          message.error(err?.response?.data?.message || '문제가 발생했습니다.');
        } finally {
          setLoading(false);
        }
      },
    });
  }, [axios, revalidate, va]);

  return (
    <Button
      {...props}
      onClick={onClick}
      loading={loading}
      style={{ color: '#fa8c16', borderColor: '#fa8c16' }}
    >
      무료출차
    </Button>
  );
};

export default FreeExitButton;
