import { atom, selector } from 'recoil';
import { Site } from '@utils/models';
import localStorageEffect from './effects/localStorageEffect';

const currentSite = atom<Site | null>({
  key: 'currentSite',
  default: null,
  effects_UNSTABLE: [localStorageEffect('currentSite')],
});

export default currentSite;

export const currentSiteId = selector({
  key: 'currentSiteId',
  get: ({ get }) => get(currentSite)?._id,
});
