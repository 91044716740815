import {
  atom,
  useResetRecoilState,
  useRecoilValue,
  useRecoilState,
  selector,
} from 'recoil';
import { useHistory } from 'react-router-dom';

import { PUBLIC_ROUTE } from '@routes/routes.constants';
import localStorageEffect from './effects/localStorageEffect';
import { Admin } from '@utils/models';

export const accessTokenState = atom({
  key: 'accessTokenState',
  default: '',
  effects_UNSTABLE: [localStorageEffect('accessTokenState')],
});

export const refreshTokenState = atom({
  key: 'refreshTokenState',
  default: '',
  effects_UNSTABLE: [localStorageEffect('refreshTokenState')],
});

const isLoggingOutState = atom({
  key: 'isLoggingOut',
  default: false,
});

export const useLogout = () => {
  const history = useHistory();
  const [isLoggingOut, setIsLoggingOut] = useRecoilState(isLoggingOutState);
  const resetAccessToken = useResetRecoilState(accessTokenState);
  const resetRefreshToken = useResetRecoilState(refreshTokenState);
  const userInfo = useRecoilValue(userInfoState);
  const tag = userInfo.permissionGroup.tag.toUpperCase();

  return () => {
    if (isLoggingOut || !history) return;
    setIsLoggingOut(true);
    // const loginPage = tag
    //   ? PUBLIC_ROUTE.LOGIN_MOBILE +
    //     (tag === 'ARCADE' ? '?type=FRIEND' : `?type=${tag}`) // 아케이드 로그아웃 시 FRIEND로 이동 (arcade면 friend로 로그인 가능하게 해둠)
    //   : PUBLIC_ROUTE.LOGIN;
    const loginPage = PUBLIC_ROUTE.LOGIN;
    resetAccessToken();
    resetRefreshToken();
    history.push(loginPage);
    console.log('logout');
    setIsLoggingOut(false);
  };
};

export const userInfoState = atom<Admin>({
  key: 'userInfoState',
  default: {
    email: '',
    name: '',
    phone: '',
    address1: '',
    _id: '',
    id: '',
    permissionGroup: {
      grantablePermissionGroups: [],
      _id: '',
      tag: '',
      name: '',
      description: '',
      permissions: {
        user: '',
        admin: '',
        site: '',
        permissionGroup: '',
        facility: '',
        vehicle: '',
        vehicleAccess: '',
        vehicleBan: '',
        patrolReport: '',
        patrolSchedule: '',
        patrolPoint: '',
        parkingIssue: '',
        parkingIssuePolicy: '',
        parkingCostPlan: '',
        discountCoupon: '',
        seasonTicket: '',
      },
      menu: [],
      createdAt: new Date(),
      updatedAt: new Date(),
      deleted: false,
      deletable: false,
      isAdmin: false,
      isFriend: false,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    passwordAttempts: 0,
    passwordChangedAt: new Date(),
    approvedStatus: 'pending',
    deleted: false,
  },
});

export const isAdminState = selector({
  key: 'isAdminState',
  get: ({ get }) =>
    ['super', 'manager'].includes(get(userInfoState).permissionGroup.tag),
});

export const isSuperState = selector({
  key: 'isSuperState',
  get: ({ get }) => get(userInfoState).permissionGroup.tag === 'super',
});
