import { atom } from 'recoil';
import localStorageEffect from './effects/localStorageEffect';
import type { NotificationPlacement } from 'antd/lib/notification';

const placementState = atom<NotificationPlacement>({
  key: 'placement',
  default: 'topRight',
  effects_UNSTABLE: [localStorageEffect('placement')],
});

export default placementState;
