import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { useSetRecoilState } from 'recoil';
import themeState, { THEMES } from '@recoil/theme';
import headerState, { HEADERS } from '@recoil/header';
import sidebarState, { SIDEBARS } from '@recoil/sidebar';

import moment from 'moment';
import useWindowSize from '@hooks/useWindowSize';

const { OPEN, COLLAPSED } = SIDEBARS;

interface Props {
  title?: ReactNode;
  children?: ReactNode;
  style?: CSSProperties;
}

export default ({ title, style, children }: Props) => {
  const setTheme = useSetRecoilState(themeState);
  const setHeader = useSetRecoilState(headerState);
  const setSidebar = useSetRecoilState(sidebarState);

  const { width: screenWidth = 1920 } = useWindowSize();
  const isMobileScreen = useMemo(() => screenWidth <= 500, [screenWidth]);

  // 시계 갱신
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(new Date()), 1000);
    return () => clearInterval(interval);
  }, [setCurrentDate]);

  // 다크 테마로 바꾸기
  useEffect(() => {
    setTheme(THEMES.DARK);
    return () => setTheme(THEMES.LIGHT);
  }, [setTheme]);

  // 헤더 숨기기
  useEffect(() => {
    setHeader(HEADERS.HIDDEN);
    return () => setHeader(HEADERS.VISIBLE);
  }, [setHeader]);

  // 좌측 메뉴 숨기기
  useEffect(() => {
    setSidebar(COLLAPSED);
    return () => setSidebar(isMobileScreen ? COLLAPSED : OPEN);
  }, [isMobileScreen, setSidebar]);

  // 좌측 메뉴 다시 열기
  const onClickToggleMenu = useCallback(
    () => setSidebar((b) => (b === COLLAPSED ? OPEN : COLLAPSED)),
    [setSidebar]
  );

  return (
    <PageHeaderWrapper style={style}>
      <MenuButton onClick={onClickToggleMenu}>
        <MenuOutlined />
      </MenuButton>
      <PageHeader>
        <span>{title}</span>
        <span>
          {children}
          <Timestamp>
            {moment(currentDate).format('YYYY-MM-DD HH:mm:ss')}
          </Timestamp>
        </span>
      </PageHeader>
    </PageHeaderWrapper>
  );
};

const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #303030;
`;

const MenuButton = styled.button`
  border: 0;
  background-color: transparent;
  font-size: 24px;
  margin-right: 8px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

const PageHeader = styled.div`
  font-size: 26px;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0;
  margin-bottom: 0;

  &::after {
    display: none;
  }
`;

const Timestamp = styled.span`
  font-weight: 400;
  letter-spacing: -0.5px;
  margin-left: 2rem;
`;
