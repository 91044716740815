import { Descriptions, DescriptionsProps } from 'antd';
import styled from 'styled-components';

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-item {
    padding-bottom: 2px !important;
  }
`;

const NarrowDescriptions = (props: DescriptionsProps) => (
  <StyledDescriptions size="small" column={1} {...props} />
);

NarrowDescriptions.Item = Descriptions.Item;

export default NarrowDescriptions;
