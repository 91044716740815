import { RotationStatus, VehicleAccess } from '@utils/models';
import { Typography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';

interface Props extends TextProps {
  rotation?: VehicleAccess['rotation'];
}

const getType = (rotation: VehicleAccess['rotation']) =>
  rotation === 'bypass' ? 'secondary' : 'danger';

export default ({ rotation, ...props }: Props) =>
  rotation ? (
    <Typography.Text {...props} type={getType(rotation)}>
      {RotationStatus[rotation]}
    </Typography.Text>
  ) : (
    <></>
  );
