import { atom } from 'recoil';

export enum HEADERS {
  VISIBLE,
  HIDDEN,
}

const headerState = atom<HEADERS>({
  key: 'headerState',
  default: HEADERS.VISIBLE,
});

export default headerState;
