import React, { useState } from 'react';
import { Button, Form, Input, message, Modal } from 'antd';

import useAxios from '@hooks/useAxios';

interface IRemarkModal {
  onCancel: Function;
  onOk: Function;
  visible: boolean;
  vehicleAccess: any;
}

export default function RemarkModal({
  onCancel,
  onOk,
  visible,
  vehicleAccess,
}: IRemarkModal) {
  const [form] = Form.useForm();
  const axios = useAxios();

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      closable={false}
      visible={visible}
      title={'비고 수정'}
      footer={[
        <Button key="cancel" onClick={() => onCancel()}>
          취소
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={async () => {
            setLoading(true);

            try {
              const values = await form.validateFields();
              console.log(values);
              await axios.patch(
                `/vehicle-accesses/${vehicleAccess._id}`,
                values
              );

              message.success('비고가 수정되었습니다.');
              onOk();
            } catch (error: any) {
              console.error(error);
              message.error('비고 수정 중 에러가 발생했습니다.');
            } finally {
              setLoading(false);
            }
          }}
        >
          수정하기
        </Button>,
      ]}
    >
      <Form form={form} preserve={false}>
        <Form.Item
          label="비고"
          name="remarks"
          initialValue={vehicleAccess?.remarks}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
