import { useMemo } from 'react';
import { Popover, Tag, TagProps } from 'antd';
import { Facility, FacilityIssueEventType as Events } from '@utils/models';

export default ({ facility, ...props }: TagProps & { facility: Facility }) => {
  const color = useMemo(
    () => (facility.hasIssue ? 'error' : 'success'),
    [facility.hasIssue]
  );

  const issues = useMemo(
    () => [
      ...new Set(
        facility.recentIssues
          .map((i) => Events[i.facilityIssueEventCode])
          .filter((v) => v)
      ),
    ],
    [facility.recentIssues]
  );

  const tag = (
    <Tag color={color} {...props}>
      {{ success: '정상', warning: '주의', error: '경보' }[color]}
    </Tag>
  );

  if (!facility.hasIssue || !issues.length) return tag;
  return <Popover content={issues.join(', ')}>{tag}</Popover>;
};
