import { Popover, Typography } from 'antd';
import React from 'react';

export const colors = {
  A: '#94470c',
  B: '#25800e',
  C: '#432e9e',
  D: '#b31b67',
  E: '#9e9a1c',
  F: '#788832',
  G: '#014666',
  H: '#5a5615',
  I: '#332508',
  J: '#0b2b19',
  K: '#343548',
  L: '#333f3e',
  M: '#016977',
  N: '#6b2e2e',
  O: '#83138d',
  P: '#392763',
  Q: '#1a685e',
  R: '#0dad13',
  S: '#431555',
  T: '#073f2c',
  U: '#294d61',
  V: '#5c4c06',
  W: '#434343',
  X: '#413959',
  Y: '#343c35',
  Z: '#52354c',
};

interface IAlpha {
  data: {
    name?: string;
    alpha: keyof typeof colors;
    cost?: {
      defaultPrice: {
        min: number;
        price: number;
      };
      additionalPrice: {
        min: number;
        price: number;
      };
      freeRoundingMinutes: number;
      deferredTime: number;
      keepFreeRounding?: boolean;
    };
  };
  popover?: boolean;
  small?: boolean;
}

export default React.memo(function Alpha({
  data: { alpha, name, cost },
  popover = false,
  small = false,
}: IAlpha) {
  const content = (
    <div
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: small ? 20 : 25,
        height: small ? 20 : 25,
        borderRadius: small ? 10 : 12.5,
        backgroundColor: colors[alpha],
        color: '#fff',
        fontWeight: 'bold',
        cursor: 'default',
        fontSize: small ? '0.8em' : '1em',
      }}
    >
      {alpha}
    </div>
  );
  if (!popover) return content;

  return (
    <Popover
      style={{ width: 100 }}
      title={name}
      content={
        <div>
          기본요금: {cost!.defaultPrice.min}분 / {cost!.defaultPrice.price}원
          <br />
          추가요금: {cost!.additionalPrice.min}분 /{' '}
          {cost!.additionalPrice.price}
          원
          <br />
          무료회차시간: {cost!.freeRoundingMinutes}분{' '}
          <Typography.Text type="secondary">
            {cost!.keepFreeRounding ? '(유지함)' : '(유지안함)'}
          </Typography.Text>
          <br />
          출차유예시간: {cost!.deferredTime}분
        </div>
      }
    >
      {content}
    </Popover>
  );
});
