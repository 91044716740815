import { useCallback, useEffect } from 'react';
import { Button, notification } from 'antd';
import NarrowDescriptions from '@contexts/components/NarrowDescriptions';

import { Handler } from '@hapi/nes';
import { VoC } from '@utils/models';
import { NesHookProps } from '../base';

import useOpener from '../opener';
import useForwardingLink from '../forwarding_link';

import { PRIVATE_ROUTE } from '@routes/routes.constants';

// VoC 접수 알림 ('voc')
const channel = '/nes/voc';

const useVoc = ({ clientRef }: NesHookProps) => {
  const open = useOpener();
  const push = useForwardingLink();

  const subscribe: Handler = useCallback(
    (voc: VoC) => {
      const key = Date.now().toString() + voc._id;

      open({
        key,
        message: `VoC 접수 알림`,
        time: voc.date,
        description: (
          <NarrowDescriptions>
            <NarrowDescriptions.Item label="주차장">
              {voc.site?.name}
            </NarrowDescriptions.Item>
            <NarrowDescriptions.Item label="제목">
              {voc.report.title}
            </NarrowDescriptions.Item>
            <NarrowDescriptions.Item label="접수자">
              {voc.customer.name}
            </NarrowDescriptions.Item>
          </NarrowDescriptions>
        ),
        actions: (
          <Button
            type="primary"
            onClick={() => {
              push({
                type: 'VoC',
                site: voc.site,
                path: `${PRIVATE_ROUTE.VOC}?vocId=${voc._id}`,
              });
              notification.close(key);
            }}
          >
            VoC 이동
          </Button>
        ),
      });
    },
    [open, push]
  );

  return useEffect(() => {
    if (!clientRef.current) return;
    const client = clientRef.current;
    client.subscribe(channel, subscribe);
    return () => void client.unsubscribe(channel, subscribe);
  }, [clientRef, subscribe]);
};

export default useVoc;
