import React from 'react';
import styled from 'styled-components';

const Footer = () => (
  <Container>
    (주)실리콘브릿지
    <br />
    <FooterWrapper>
      <div>
        대표이사: 김태호
        <br />
        사업자등록번호: 395-87-00215
        <br />
        주소: 경기 성남시 중원구 갈마치로288번길 14, 731호 (상대원동, 성남 SK V1
        tower)
      </div>
      <div>
        대표전화: 1670-8891
        <br />
        통신판매신고: 제2016-성남분당-0136호
      </div>
    </FooterWrapper>
    <span>
      <a
        href="https://eyevacs.github.io/data/pdf/eyevacs_privacy_policy.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        개인정보처리방침
      </a>
      {' | '}
      <a
        href="https://eyevacs.github.io/data/pdf/eyevacs_terms_of_service.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        이용약관
      </a>
    </span>
    <span>
      &copy; 2020-{new Date().getFullYear()} Silicon Bridge, Inc. All Rights
      Reserved.
    </span>
  </Container>
);

export default Footer;

const Container = styled.footer`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  color: #959ca3;
  font-size: 12.5px;
  padding: 18px 20px;
  word-break: keep-all;
  background-color: #000000;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 8px 0;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &:first-child {
      margin-right: 32px;
    }
  }
`;
