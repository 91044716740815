import React from 'react';
import { useHistory } from 'react-router-dom';
import { Select as AntSelect } from 'antd';
import styled from 'styled-components';
import useSites from '@hooks/useSites';
import { userInfoState } from '@recoil/auth';
import currentSiteState from '@recoil/current-site';
import { useRecoilState, useRecoilValue } from 'recoil';
import SiteName from './SiteName';

export default React.memo(() => {
  const sites = useSites();
  const history = useHistory();
  const profile = useRecoilValue(userInfoState);
  const [currentSite, setCurrentSite] = useRecoilState(currentSiteState);

  return (
    <Select
      showSearch
      defaultActiveFirstOption={false}
      optionFilterProp="children"
      filterOption={(input, option) => {
        const name = option?.children?.props?.site?.name;
        return (
          typeof name === 'string' &&
          name.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      onChange={(siteId) => {
        setCurrentSite(sites.find((v) => v._id === siteId)!);
        history.push('');
      }}
      style={{ width: '100%' }}
      placeholder="주차장 선택"
      loading={!sites}
      disabled={!!profile.permissionGroup.site}
      value={
        profile.permissionGroup.site ? currentSite?.name : currentSite?._id
      }
    >
      {sites &&
        !profile.permissionGroup.site &&
        sites.map((site) => (
          <Select.Option key={site._id} value={site._id}>
            <SiteName site={site} mode="dropdown" />
          </Select.Option>
        ))}
    </Select>
  );
});

const Select = styled(AntSelect)`
  &&& .ant-select-selector {
    background-color: #000;
    color: #fff;
  }

  .ant-select-selection-item {
    display: flex;
  }
`;
