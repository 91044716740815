import { Handler } from '@hapi/nes';
import { NesHookProps } from '../base';
import { FacilityIssue, FacilityIssueEventType } from '@utils/models';

import useOpener from '../opener';
import useForwardingLink from '../forwarding_link';

import { useCallback, useEffect } from 'react';
import { Typography } from 'antd';

import { PRIVATE_ROUTE } from '@routes/routes.constants';

// 설비이슈 알림 ('facility_issue')
const channel = '/nes/facility_issue';

const useFacilityIssue = ({ clientRef }: NesHookProps) => {
  const open = useOpener();
  const push = useForwardingLink();

  const subscribe: Handler = useCallback(
    (issue: FacilityIssue) =>
      open({
        key: issue._id,
        time: issue.occurredAt,
        message: '설비이슈 알림',
        description: (
          <>
            <Typography.Link
              onClick={() =>
                push({
                  type: '주차장',
                  site: issue.site,
                  path: `${PRIVATE_ROUTE.PARKING_LOT_MANAGE}/view/${issue.site._id}`,
                })
              }
            >
              {issue.site.name}
            </Typography.Link>{' '}
            주차장의{' '}
            <Typography.Link
              onClick={() =>
                push({
                  type: '설비',
                  site: issue.site,
                  path: `${PRIVATE_ROUTE.FACILITY_MANAGE}/view/${issue.facility._id}`,
                })
              }
            >
              {issue.facility.name}
            </Typography.Link>{' '}
            설비에서 {FacilityIssueEventType[issue.facilityIssueEventCode]}{' '}
            이슈가 발생했습니다.
          </>
        ),
      }),
    [open, push]
  );

  return useEffect(() => {
    if (!clientRef.current) return;
    const client = clientRef.current;
    client.subscribe(channel, subscribe);
    return () => void client.unsubscribe(channel, subscribe);
  }, [clientRef, subscribe]);
};

export default useFacilityIssue;
