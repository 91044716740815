import React from 'react';
import { Modal } from 'antd';
import { differenceInDays, endOfDay } from 'date-fns';
import { renderDate } from '@utils';
import { PRIVATE_ROUTE } from './routes.constants';
import { Admin } from '@utils/models';
import { History } from 'history';
export const key = 'skipPasswordPromptUntil';

interface Params {
  data: Admin;
  history: History;
}

export default ({ data, history }: Params) => {
  const now = new Date();
  const skip = localStorage?.getItem(key);
  if (skip && now < new Date(skip)) return;

  const last = new Date(data.passwordChangedAt || data.createdAt);
  const diff = differenceInDays(now, last);
  if (diff < 30) return;

  const disclaimer = (
    <article
      style={{
        opacity: 0,
        fontSize: 3,
        display: 'flex',
        flexFlow: 'column',
      }}
    >
      <a
        hrefLang="ko-KR"
        href="https://www.boannews.com/media/view.asp?idx=56986"
      >
        NIST, “비밀번호 자주 바꾸면 해킹에 더 취약하다”
      </a>
      <a
        hrefLang="en-GB"
        href="https://www.ncsc.gov.uk/blog-post/problems-forcing-regular-password-expiry"
      >
        The problems with forcing regular password expiry
      </a>
      <a
        hrefLang="en-US"
        href="https://www.engadget.com/2019-04-24-microsoft-password-expiration-security.html"
      >
        Microsoft knows password-expiration policies are useless
      </a>
    </article>
  );

  Modal.confirm({
    width: '500px',
    maskClosable: true,
    title: '비밀번호를 변경해 주세요!',
    okText: '지금 변경하기',
    onOk: () => history.push(PRIVATE_ROUTE.PROFILE + '/password'),
    cancelText: '나중에 변경하기',
    onCancel: () => {
      localStorage?.setItem(key, endOfDay(new Date()).toISOString());
    },
    content: (
      <>
        마지막으로 비밀번호를 변경한 지 <strong>{diff}일</strong>이 지났습니다.
        <br />
        안전한 사용을 위해 주기적으로 비밀번호를 변경해 주세요.
        <br />
        {disclaimer}
        마지막 변경일: {renderDate(last)}
      </>
    ),
  });
};
