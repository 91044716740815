import { FC, useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  ModalProps,
  Table,
  Typography,
} from 'antd';

import { useRecoilValue } from 'recoil';
import { userInfoState } from '@recoil/auth';
import currentSiteState from '@recoil/current-site';

import moment, { Moment } from 'moment';
import { startOfDay, endOfDay } from 'date-fns';
import { renderDate, renderPrice } from '@utils/index';

import useSWR from 'swr';
import useAxios from '@hooks/useAxios';
import { stringifyUrl } from 'query-string';

import { Admin, CashRecord, Facility } from '@utils/models';
import FacilitySelect from '@components/FacilitySelect';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from '@routes/routes.constants';

type Range = [Moment, Moment] | null;
type Data = { docs: CashRecord[]; meta: { total: number } };

interface Props extends ModalProps {
  value: number;
  facility: string;
  setVisible: (visible: boolean) => void;
}

const CashRecordModal: FC<Props> = ({
  value,
  facility,
  setVisible,
  ...props
}) => {
  const axios = useAxios();
  const currentSite = useRecoilValue(currentSiteState)!;
  const userInfo = useRecoilValue(userInfoState);

  const [searchForm] = Form.useForm();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [range, setRange] = useState<Range>([moment(), moment()]);
  const [searchFacility, setSearchFacility] = useState<string>();

  const [createForm] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const url = '/cash-record';
  const endpoint = useMemo(
    () =>
      stringifyUrl({
        url,
        query: {
          site: currentSite._id,
          ...(searchFacility && { facility: searchFacility }),
          ...(range && {
            dateAfter: startOfDay(range[0].toDate()).toISOString(),
            dateBefore: endOfDay(range[1].toDate()).toISOString(),
          }),
          page,
          count,
        },
      }),
    [count, currentSite._id, page, range, searchFacility]
  );

  const { data, revalidate, isValidating } = useSWR<Data>(endpoint);
  const docs = useMemo(() => data?.docs, [data?.docs]);

  useEffect(() => void searchForm.submit(), [searchForm]);
  const onSearch = useCallback(
    ({ facility, range }: { facility: string; range: Range }) => {
      setPage(1);
      setRange(range);
      setSearchFacility(facility);
    },
    []
  );

  const onCreate = useCallback(
    ({ remarks }: { remarks?: string }) => {
      if (loading) return;
      setLoading(true);
      axios
        .post(url, {
          value,
          remarks,
          facility,
          admin: userInfo._id,
          site: currentSite._id,
          date: new Date().toISOString(),
        })
        .then(() => [
          revalidate(),
          createForm.resetFields(),
          message.success('근무자 변경이 등록되었습니다.'),
        ])
        .catch(() => message.error('근무자 변경 등록 중 오류가 발생했습니다.'))
        .finally(() => setLoading(false));
    },
    [
      axios,
      createForm,
      currentSite._id,
      facility,
      loading,
      revalidate,
      userInfo._id,
      value,
    ]
  );

  return (
    <Modal
      {...props}
      destroyOnClose
      closable={false}
      width={750}
      bodyStyle={{ padding: 0 }}
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ fontSize: '1.15rem' }}>근무자 변경 이력</span>
          <InlineForm
            form={searchForm}
            layout="inline"
            preserve={false}
            onFinish={onSearch}
            initialValues={{ facility, range }}
          >
            <Form.Item name="facility">
              <FacilitySelect allowClear kind="exit_lpr" />
            </Form.Item>

            <Form.Item name="range">
              <DatePicker.RangePicker />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isValidating}>
                검색
              </Button>
            </Form.Item>
          </InlineForm>
        </div>
      }
      footer={
        <InlineForm
          form={createForm}
          layout="inline"
          preserve={false}
          onFinish={onCreate}
        >
          <Form.Item name="remarks" style={{ flex: 1 }}>
            <Input.Search
              autoFocus
              type="primary"
              loading={loading}
              enterButton="근무자 변경"
              placeholder="비고를 입력하세요."
              onSearch={() => createForm.submit()}
            />
          </Form.Item>

          <Form.Item>
            <Button onClick={() => setVisible(false)}>닫기</Button>
          </Form.Item>
        </InlineForm>
      }
    >
      <Table
        rowKey="_id"
        loading={!data}
        dataSource={docs}
        columns={[
          {
            title: '설비',
            dataIndex: 'facility',
            render: (facility: Facility) => (
              <Link
                to={`${PRIVATE_ROUTE.FACILITY_MANAGE}/view/${facility._id}`}
              >
                {facility.name}
              </Link>
            ),
          },
          {
            title: '일시',
            dataIndex: 'date',
            render: (v) => renderDate(v),
          },
          {
            title: '금액',
            dataIndex: 'value',
            render: (v) => (
              <Typography.Text strong type={v ? undefined : 'secondary'}>
                {renderPrice(v)}
              </Typography.Text>
            ),
          },
          {
            title: '근무자',
            dataIndex: 'admin',
            render: (admin: Admin) => (
              <Link to={`${PRIVATE_ROUTE.USER_ADMIN_MANAGE}/view/${admin.id}`}>
                {admin.name}
              </Link>
            ),
          },
          {
            title: '비고',
            dataIndex: 'remarks',
            render: (v) => (
              <Typography.Text type={v ? undefined : 'secondary'}>
                {v || '-'}
              </Typography.Text>
            ),
          },
        ]}
        pagination={{
          style: { padding: '0 1rem' },
          total: data?.meta.total,
          showTotal: (total) => `총 ${total}개`,
          current: page,
          defaultCurrent: page,
          pageSize: count,
          onChange: (p, c) => [setPage(p), setCount(c || count)],
        }}
      />
    </Modal>
  );
};

export default CashRecordModal;

const InlineForm: typeof Form = styled(Form)`
  gap: 1rem;

  &&& {
    .ant-form-item {
      margin-right: 0;
    }
  }
`;
