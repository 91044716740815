import React, { useState } from 'react';
import { Button, Form, Modal, Input, message } from 'antd';
import useAxios from '@hooks/useAxios';
import { VEHICLE_REGEX } from '@utils/regex';

interface UpdateVehicleModalProps {
  onCancel: Function;
  onOk: Function;
  id: string;
  vehicle?: string | null;
  visible: boolean;
}

export default function UpdateVehicleModal({
  onCancel,
  onOk,
  id,
  vehicle,
  visible,
}: UpdateVehicleModalProps) {
  const [form] = Form.useForm();
  const axios = useAxios();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      closable={false}
      visible={visible}
      title={'차량정보 수정'}
      footer={[
        <Button key="cancel" onClick={() => onCancel()}>
          취소
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            form.validateFields().then((v: any) => {
              if (v.vehicle.match(VEHICLE_REGEX) === null) {
                message.error('차량번호를 제대로 입력해 주세요!');
                return;
              }
              setLoading(true);
              axios
                .patch(`/vehicle-accesses/${id}`, { vehicle: v.vehicle })
                .then(() => {
                  setLoading(false);
                  onOk();
                });
            });
          }}
        >
          적용
        </Button>,
      ]}
    >
      <Form
        form={form}
        preserve={false}
        initialValues={{ vehicle: vehicle || '' }}
      >
        <Form.Item label="차량번호" name="vehicle">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
