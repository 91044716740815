import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Modal, List, Input, InputRef, Spin, Button } from 'antd';
import styled from 'styled-components';

import useAxios from '@hooks/useAxios';
import queryString from 'query-string';
import { VehicleAccess } from '@utils/models';

import { useRecoilValue } from 'recoil';
import currentSiteState from '@recoil/current-site';

import AccessLogText from './AccessLogText';
import { formatDistanceTo } from '@utils/index';

interface ISearchVehicle {
  visible: boolean;
  setVisible: (value: boolean) => void;
  setVehicle: (value: string) => void;
  setVa: (value: VehicleAccess) => void;
}

const SearchVehicle: React.FC<ISearchVehicle> = ({
  setVehicle,
  visible,
  setVisible,
  setVa,
}) => {
  const axios = useAxios();
  const currentSite = useRecoilValue(currentSiteState)!;

  const [query, setQuery] = useState('');
  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current?.focus();
  });

  const [vas, setVas] = useState<VehicleAccess[]>();
  const [isValidating, setIsValidating] = useState<boolean>(false);

  const search = useCallback(async () => {
    setIsValidating(true);
    const res = await axios.get<{ vehicleAccesses: VehicleAccess[] }>(
      queryString.stringifyUrl({
        url: '/vehicle-accesses',
        query: {
          siteId: currentSite._id,
          vehicle: query,
          status: 'inParking',
          ticketType: 'default' /** 정기권 아님*/,
          count: 10,
        },
      })
    );
    setVas(res.data?.vehicleAccesses);
    setIsValidating(false);
  }, [axios, currentSite._id, query]);

  return (
    <Modal
      title="차량 검색"
      visible={visible}
      closable={false}
      onOk={() => setVisible(false)}
      afterClose={() => [setQuery(''), setVas(undefined)]}
      footer={<Button onClick={() => setVisible(false)}>취소</Button>}
    >
      <Row>
        <Input
          ref={inputRef}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="차량번호를 입력해 주세요."
          style={{ marginRight: 8, flex: 1 }}
          onKeyDown={(e) => {
            if (!isValidating && e.key === 'Enter') search();
          }}
        />
        <Button loading={isValidating} type="primary" onClick={search}>
          검색
        </Button>
      </Row>
      {isValidating ? (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      ) : (
        <List
          bordered
          locale={{ emptyText: '주차 중인 일반 차량이 없습니다.' }}
          dataSource={vas}
          style={{ marginTop: 16 }}
          rowKey={(record) => record._id}
          renderItem={(v) => (
            <List.Item
              style={{
                cursor: 'pointer',
                alignItems: 'start',
                flexDirection: 'column',
              }}
              onClick={() => {
                setVehicle(v.vehicle);
                setVa(v);
                setVisible(false);
              }}
            >
              {v.vehicle && <strong>{v.vehicle}</strong>}
              {v.entry && (
                <span>
                  입차시간: <AccessLogText log={v.entry} />
                </span>
              )}
              {v.entry && (
                <span>주차시간: {formatDistanceTo(v.entry.accessedAt)}</span>
              )}
              {v.payment?.discountCouponList?.length > 0 && (
                <span>
                  적용된 할인권: {v.payment?.discountCouponList?.length}개
                </span>
              )}
            </List.Item>
          )}
        />
      )}
    </Modal>
  );
};

export default SearchVehicle;

const SpinWrapper = styled.div`
  min-height: 128px;
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
