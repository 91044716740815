import { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import {
  Button,
  ButtonProps,
  CardProps,
  Col,
  Descriptions,
  Divider,
  InputNumber,
  InputNumberProps,
  message,
  Result,
  Row,
  Select,
  SelectProps,
  Space,
  Table,
} from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  FileSearchOutlined,
  HistoryOutlined,
  LoginOutlined,
  LogoutOutlined,
  ReloadOutlined,
  SearchOutlined,
  ThunderboltOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';

import { renderPrice } from '@utils';
import { startOfDay } from 'date-fns';
import { AccessLog, Facility, VehicleAccess } from '@utils/models';

import { useHotkeys } from 'react-hotkeys-hook';

import useSWR from 'swr';
import useAxios from '@hooks/useAxios';
import axiosError from '@utils/axios-error';

import { stringifyUrl } from 'query-string';
import { useQueryParam, StringParam, BooleanParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import { PRIVATE_ROUTE } from '@routes/routes.constants';

import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import localStorageEffect from '@recoil/effects/localStorageEffect';
import { userInfoState } from '@recoil/auth';
import currentSiteState from '@recoil/current-site';
import footerState, { FOOTERS as F } from '@recoil/footer';

import MonitorHeader from '@components/MonitorHeader';
import LayoutContent from '@components/LayoutContent';
import LayoutContentWrapper from '@components/LayoutContentWrapper';

import CouponList from '@components/CouponList';
import CouponRegister from '@components/CouponRegister';
import SearchVehicle from '@components/SearchVehicle';
import VehicleDescriptions from '@components/VehicleDescriptions';

import { getColumns } from './Log';
import InfoModal from './modals/InfoModal';
import CashRecordModal from './modals/CashRecordModal';
import ManualEntryModal from './modals/ManualEntryModal';
import UpdateVehicleModal from './modals/UpdateVehicleModal';
import MonitorCard from './components/MonitorCard';
import PaymentLogs from './components/PaymentLogs';
import PaymentPayments from './components/PaymentPayments';
import DeleteButton from './components/DeleteButton';
import FreeExitButton from './components/FreeExitButton';
import { PayMachineCard } from './components/CamCard';
import AccessLogText from '@components/AccessLogText';

const VA_URL = '/vehicle-accesses';
const FACILITY_URL = '/facilities';

const swrOptions = { refreshInterval: 1000, refreshWhenHidden: true };

// 유인정산 활성화 여부
// 이 후크는 사이드바에서도 사용하므로 currentSite 없을 수도 있음

export const useEnabled = () => {
  const currentSite = useRecoilValue(currentSiteState);
  const { permissionGroup } = useRecoilValue(userInfoState);
  const [flag] = useQueryParam('jinmarket', BooleanParam);

  return useMemo(
    () =>
      (currentSite?._id === 'SBI_EYEVACS_0001' || flag) &&
      ['super', 'manager'].includes(permissionGroup.tag),

    // 현재까지는 진시장 운영센터만 허용
    [currentSite?._id, flag, permissionGroup.tag]
  );
};

// 현재 주차장 ID

const useSiteId = () => {
  const currentSite = useRecoilValue(currentSiteState)!;
  return useMemo(() => currentSite._id, [currentSite]);
};

// 유인정산 페이지 설정

const configState = atom<
  Record<string, Record<string, string | undefined> | undefined>
>({
  default: {},
  key: 'mannedSettlementConfigState',
  effects_UNSTABLE: [localStorageEffect('mannedSettlementConfigState')],
});

// 설비 선택 컴포넌트 (자동 선택, 선택 기억)

interface FacilitySelectProps extends SelectProps<Facility['_id']> {
  kind: Facility['kind'];
  component?: typeof Select;
}

const FacilitySelect = ({
  kind,
  value,
  onChange: originalOnChange,
  component: Component = Select,
  ...props
}: FacilitySelectProps) => {
  const siteId = useSiteId();
  const { data } = useSWR<{ facilities: Facility[] }>(
    stringifyUrl({ url: FACILITY_URL, query: { siteId, kind } })
  );
  const options = useMemo(
    () =>
      data?.facilities?.map((facility) => ({
        key: facility._id,
        value: facility._id,
        label: facility.name,
      })),
    [data]
  );

  const [config, setConfig] = useRecoilState(configState);
  const onChange = useCallback<
    Exclude<FacilitySelectProps['onChange'], undefined>
  >(
    (value, option) => {
      originalOnChange?.(value, option);
      setConfig((config) => ({
        ...config,
        [siteId]: { ...config[siteId], [kind]: value },
      }));
    },
    [kind, originalOnChange, setConfig, siteId]
  );
  useEffect(() => {
    if (!value && options) {
      const initialValue = config[siteId]?.[kind] || options[0]?.value;
      const option = options.find((o) => o.value === initialValue);
      if (option) onChange(initialValue, option);
    }
  }, [value, onChange, options, kind, config, siteId]);

  return (
    <Component
      value={value}
      onChange={onChange}
      options={options}
      loading={!data}
      {...props}
    />
  );
};

// 출구정산기 카메라

const MachineCard = ({ id, ...props }: { id?: string } & CardProps) => {
  const { data } = useSWR<Facility>(id ? `/facilities/${id}` : null);
  return <>{data && <PayMachineCard facility={data} {...props} />}</>;
};

// 받은 현금 처리하는 컴포넌트

interface CostInputProps extends InputNumberProps {
  total: number;
  remaining: number;
}

const CostInput = ({ total, remaining, ...props }: CostInputProps) => {
  const setAll = useCallback(() => props.onChange?.(total), [props, total]);
  useHotkeys('num_add', () => void setAll(), [setAll]);

  return (
    <Descriptions
      bordered
      column={1}
      labelStyle={{ fontSize: '1.12rem', textAlign: 'center', width: '8rem' }}
      contentStyle={{ fontSize: '1.12rem', textAlign: 'right' }}
    >
      <Descriptions.Item label="주차 요금">
        {renderPrice(total)}
      </Descriptions.Item>

      <Descriptions.Item
        label="받은 금액"
        contentStyle={{ padding: '0 12px', backgroundColor: '#e83b6930' }}
      >
        <Row>
          <Col>
            <Button onClick={setAll}>전부 현금</Button>
          </Col>
          <Col
            flex={1}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <RightInputNumber
              {...props}
              min={0}
              max={total}
              bordered={false}
              controls={false}
              formatter={(v) => renderPrice(Number(v))}
              parser={(v) => Number(v?.replace(/\D/g, '')) || 0}
              style={{ fontSize: '1.12rem', flex: 1 }}
            />
          </Col>
        </Row>
      </Descriptions.Item>

      <Descriptions.Item label="남은 금액">
        {renderPrice(remaining)}
      </Descriptions.Item>
    </Descriptions>
  );
};

// 현금 누계

const useCashTotal = () => {
  const siteId = useSiteId();
  const { data, revalidate } = useSWR<{ total: number }>(
    stringifyUrl({
      url: `${VA_URL}/cash-total`,
      query: { siteId, exitAfter: startOfDay(new Date()).toISOString() },
    })
  );
  return { revalidate, total: useMemo(() => data?.total, [data]) };
};

// 쿼리 차량 기록

const useQueryVA = () => {
  const [vaId, setVaId] = useQueryParam('vaId', StringParam);
  const { data, revalidate } = useSWR<{ vehicleAccess: VehicleAccess }>(
    vaId ? `${VA_URL}/${vaId}` : null,
    swrOptions
  );

  return {
    revalidate,
    setQuery: setVaId,
    va: useMemo(() => data?.vehicleAccess, [data]),
  };
};

// 최근 차량 기록

const useRecentVA = (facilityId: string | undefined, count: number) => {
  const siteId = useSiteId();
  const endpoint = useMemo(
    () =>
      stringifyUrl({
        url: VA_URL,
        query: {
          siteId,
          facilityId,
          count,
          skip: true,
          populateSeasonTicket: true,
        },
      }),
    [siteId, facilityId, count]
  );

  const { data, revalidate } = useSWR<{ vehicleAccesses: VehicleAccess[] }>(
    facilityId ? endpoint : null,
    swrOptions
  );

  return {
    revalidate,
    va: useMemo(() => data?.vehicleAccesses[0], [data]),
    vas: useMemo(() => data?.vehicleAccesses ?? [], [data]),
  };
};

// 위 둘을 합친 통합 차량 기록

const useVA = (facilityId: string | undefined, count: number) => {
  const { va: vaQ, setQuery, revalidate: rQ } = useQueryVA();
  const { va: vaR, vas, revalidate: rR } = useRecentVA(facilityId, count);

  return {
    vas,
    setQuery,
    va: useMemo(() => vaQ || vaR, [vaQ, vaR]),
    query: useMemo(() => vaQ !== undefined, [vaQ]),
    revalidate: useCallback(() => Promise.all([rQ(), rR()]), [rQ, rR]),
  };
};

// 위 셋을 합친 진짜 통합 데이터

const useDB = (facilityId: string | undefined, count: number) => {
  const { total, revalidate: rT } = useCashTotal();
  const { va, vas, query, setQuery, revalidate: rV } = useVA(facilityId, count);
  const revalidate = useCallback(() => Promise.all([rT(), rV()]), [rT, rV]);

  return { total, va, vas, query, setQuery, revalidate };
};

// 차량 관련 액션 컴포넌트

interface VehicleActionsProps {
  va?: VehicleAccess;
  revalidate: () => void;
}

const VehicleActions = ({ va, revalidate }: VehicleActionsProps) => {
  const [updateVisible, setUpdateVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);

  if (!va) return <></>;
  return (
    <Space>
      <UpdateVehicleModal
        id={va?._id}
        vehicle={va?.vehicle}
        visible={updateVisible}
        onCancel={() => setUpdateVisible(false)}
        onOk={() => [setUpdateVisible(false), revalidate()]}
      />

      <InfoModal
        vaId={va._id}
        revalidate={revalidate}
        visible={infoVisible}
        setVisible={setInfoVisible}
      />

      <Button icon={<EditOutlined />} onClick={() => setUpdateVisible(true)}>
        차량번호 수정
      </Button>

      <Button
        icon={<FileSearchOutlined />}
        onClick={() => setInfoVisible(true)}
      >
        정보 조회
      </Button>

      <FreeExitButton
        va={va}
        revalidate={revalidate}
        icon={<ExportOutlined />}
      />

      <DeleteButton va={va} revalidate={revalidate} icon={<DeleteOutlined />} />
    </Space>
  );
};

// 로딩과 단축키가 있는 버튼
interface ActionButtonProps extends ButtonProps {
  onClick: () => '' | void | Promise<any>;
  hotkey: string;
}

const ActionButton = ({ onClick, hotkey, ...props }: ActionButtonProps) => {
  const [v, s] = useState(false);
  const cb = useCallback(() => onClick() || Promise.resolve(), [onClick]);
  const fn = useCallback(() => [s(true), cb().finally(() => s(false))], [cb]);

  useHotkeys(hotkey, () => void (!v && fn()), [v, fn]);
  return <Button {...props} loading={v} onClick={fn} />;
};

// 최상단 차량번호 (제목)

interface VehicleAccessIndicatorProps {
  va?: VehicleAccess;
  query: boolean;
  setQuery: (query: string | undefined) => void;
}

const VehicleAccessIndicator = ({
  va,
  query,
  setQuery,
}: VehicleAccessIndicatorProps) => {
  return (
    <nav
      style={{
        display: 'flex',
        alignItems: 'center',
        color: query ? '#fa8c16' : undefined,
      }}
    >
      <Title>유인정산</Title>
      {va && <strong>{va.vehicle || '미인식'}</strong>}
      {query && (
        <Button
          shape="circle"
          size="large"
          icon={<CloseOutlined />}
          style={{
            color: '#fa8c16',
            borderColor: '#fa8c16',
            marginLeft: '0.75rem',
          }}
          onClick={() => setQuery(undefined)}
        />
      )}
    </nav>
  );
};

// 차량 관리 > 차량 관리 페이지 테이블 변형

interface LogTableProps {
  vas: VehicleAccess[];
}

const LogTable = ({ vas }: LogTableProps) => {
  const isDateColumn = (c: any): c is { title: string } =>
    typeof c.title === 'string' && c.title.endsWith('일시');

  const logColumns = getColumns()
    .filter((c) =>
      ['차량번호', '권종', '상태', '입차일시', '출차일시'].includes(
        c.title ? c.title.toString() : ''
      )
    )
    .map((c) => ({
      ...c,
      sorter: false,
      ...(isDateColumn(c) && {
        title: c.title.slice(0, -2),
        render: (log: AccessLog | undefined) => (
          <AccessLogText log={log} dateFormat="HH:mm" />
        ),
      }),
    }));

  return (
    <StyledTable
      size="small"
      rowKey="_id"
      dataSource={vas}
      pagination={false}
      columns={logColumns}
    />
  );
};

// 진짜 유인정산 컴포넌트

export default ({ gutter = 8 }: { gutter?: number }) => {
  const axios = useAxios();
  const enabled = useEnabled();
  const { push } = useHistory();

  // LPR 및 유인정산기 ID
  const [exitId, setExitId] = useState<Facility['_id']>();
  const [machineId, setMachineId] = useState<Facility['_id']>();

  // 데이터
  const [count, setCount] = useState(6);
  const { total, va, vas, query, setQuery, revalidate } = useDB(exitId, count);

  // 받은 현금 관련 상태
  const [cash, setCash] = useState(0);
  useEffect(() => setCash(va?.payment?.cash || 0), [va?.payment?.cash]);

  const totalCost = useMemo(() => va?.payment?.totalCost || 0, [va]);
  const cost = useMemo(() => Math.max(0, totalCost - cash), [cash, totalCost]);

  // 수동 입출차 관련 상태
  const [isEntry, setIsEntry] = useState(true);
  const [manualVisible, setManualVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [cashRecordVisible, setCashRecordVisible] = useState(false);

  // 커스텀 웹소켓 데이터 전송
  const dispatch = useCallback(
    (name: string, data: any) =>
      axios({
        method: 'POST',
        url: '/test/websocket-custom',
        baseURL: process.env.REACT_APP_DEVICE_URL,
        data: { id: machineId, data: { event_name: name, ...data } },
      }),
    [axios, machineId]
  );

  // 정산기 요금 전송
  const sendCost = useCallback(
    () =>
      machineId &&
      va &&
      dispatch('trans_fee', { discount_fee: cash, total_fee: cost })
        .then(() => axios.patch(`${VA_URL}/${va._id}`, { payment: { cash } }))
        .then(() => revalidate())
        .then(() => message.success('요금이 전송되었습니다.'))
        .catch(axiosError('요금 전송 중 오류가 발생했습니다.')),
    [dispatch, machineId, va, cash, cost, axios, revalidate]
  );

  // 정산기 화면 갱신
  const refreshMachine = useCallback(
    () =>
      machineId &&
      va?._id &&
      dispatch('trans_vehicle', { external_id: va?._id })
        .then(() => message.success('정산기 화면을 갱신했습니다.'))
        .catch(axiosError('정산기 화면 갱신 중 오류가 발생했습니다.')),
    [dispatch, machineId, va?._id]
  );

  // 새 차량이 들어오면 정산기에 화면 갱신 요청
  // LPR-정산기 시리얼 통신이 연결되면 더 이상 소프트웨어적으로 보낼 필요 없음
  // useEffect(() => void refreshMachine(), [refreshMachine]);

  // 푸터 가리기
  const footer = useSetRecoilState(footerState);
  useEffect(() => [footer(F.HIDDEN), () => footer(F.VISIBLE)][1], [footer]);

  const toolkit = (
    <>
      <Space split={<Divider type="vertical" />}>
        <FacilitySelect
          value={exitId}
          onChange={setExitId}
          kind="exit_lpr"
          placeholder="출구 LPR 선택"
          style={{ minWidth: '150px' }}
        />

        <Space>
          <SearchVehicle
            visible={searchVisible}
            setVisible={setSearchVisible}
            setVehicle={() => void 0}
            setVa={(va) => setQuery(va._id)}
          />

          <ManualEntryModal
            visible={manualVisible}
            setVisible={setManualVisible}
            revalidate={revalidate}
            isEntry={isEntry}
            goPage={false}
            onCreated={(va) => setQuery(va._id)}
          />

          <Button
            icon={<SearchOutlined />}
            onClick={() => setSearchVisible(true)}
          >
            차량 검색
          </Button>

          <Button
            icon={<LoginOutlined />}
            onClick={() => [setIsEntry(true), setManualVisible(true)]}
          >
            수동입차
          </Button>

          <Button
            icon={<LogoutOutlined />}
            onClick={() => [setIsEntry(false), setManualVisible(true)]}
          >
            수동출차
          </Button>
        </Space>

        <VehicleActions va={va} revalidate={revalidate} />
      </Space>

      <Space>
        <InputNumber
          value={count}
          onChange={(v) => setCount(Number(v) || 0)}
          min={1}
          max={20}
          addonBefore={<HistoryOutlined />}
          style={{ width: '90px' }}
          formatter={(v) => `${v}건`}
          parser={(v) => Number(v?.replace(/\D/g, '')) || 0}
        />

        {exitId !== undefined && (
          <span>
            <CashRecordModal
              value={total || 0}
              facility={exitId}
              visible={cashRecordVisible}
              setVisible={setCashRecordVisible}
            />
            <Button
              icon={<UserSwitchOutlined />}
              disabled={total === undefined}
              onClick={() => setCashRecordVisible(true)}
            >
              근무자 변경
            </Button>
          </span>
        )}

        <span>
          <FacilitySelect
            value={machineId}
            onChange={setMachineId}
            kind="exit_auto_pay_machine"
            placeholder="출구정산기 선택"
            component={StyledSelect}
          />
          <ActionButton
            onClick={refreshMachine}
            hotkey="num_decimal"
            icon={<ReloadOutlined />}
            style={{
              borderRadius: 0,
              borderLeftWidth: 0,
              borderColor: '#e83b69',
            }}
          >
            화면 갱신
          </ActionButton>
          <ActionButton
            onClick={sendCost}
            hotkey="num_subtract"
            type="primary"
            icon={<ThunderboltOutlined />}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeftWidth: 0,
            }}
          >
            요금 전송
          </ActionButton>
        </span>
      </Space>
    </>
  );

  const components = va && (
    <Row gutter={[gutter, gutter]}>
      <Col
        span={14}
        style={{ display: 'flex', flexDirection: 'column', gap: gutter }}
      >
        <Row gutter={[gutter, gutter]}>
          <Col span={12}>
            <MonitorCard va={va} type="entry" revalidate={revalidate} />
          </Col>

          <Col span={12}>
            <MonitorCard va={va} type="exit" revalidate={revalidate} />
          </Col>
        </Row>

        <StyledPaymentPayments va={va} small cardless target={machineId} />
        <StyledPaymentLogs va={va} small cardless />
      </Col>

      <Col
        span={5}
        style={{ display: 'flex', flexDirection: 'column', gap: gutter }}
      >
        <VehicleDescriptions
          va={va}
          labelStyle={{ fontSize: '1.15rem' }}
          contentStyle={{ fontSize: '1.15rem' }}
        />
        <CouponRegister va={va} revalidate={revalidate} />
        <CouponList small va={va} revalidate={revalidate} style={{ flex: 1 }} />
      </Col>

      <Col
        span={5}
        style={{ display: 'flex', flexDirection: 'column', gap: gutter }}
      >
        <CostInput
          total={totalCost}
          remaining={cost}
          value={cash}
          onChange={(v) => setCash(Number(v) || 0)}
        />

        <MachineCard id={machineId} />

        <LogTable vas={vas} />
      </Col>
    </Row>
  );

  return (
    <LayoutContentWrapper>
      <RemoveScrollbar />

      {enabled ? (
        <>
          <MonitorHeader
            style={{ marginBottom: gutter }}
            title={
              <VehicleAccessIndicator
                va={va}
                query={query}
                setQuery={setQuery}
              />
            }
          >
            현금누계: <strong>{renderPrice(total)}</strong>
          </MonitorHeader>

          <LayoutContent
            style={{
              marginBottom: gutter,
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#141414',
            }}
          >
            {toolkit}
          </LayoutContent>

          {components}
        </>
      ) : (
        <>
          <MonitorHeader />
          <Result
            status="error"
            title="조회 권한이 없습니다."
            extra={
              <Button type="primary" onClick={() => push(PRIVATE_ROUTE.MAIN)}>
                메인 화면으로 돌아가기
              </Button>
            }
          />
        </>
      )}
    </LayoutContentWrapper>
  );
};

const Title = styled.span`
  &:not(:last-child)::after {
    content: ':';
    margin-right: 0.5rem;
  }
`;

const RemoveScrollbar = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const StyledSelect: typeof Select = styled(Select)`
  min-width: 100px;

  &&& {
    .ant-select-selector {
      border-color: #e83b69;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const RightInputNumber = styled(InputNumber)`
  & .ant-input-number-input {
    text-align: right;
  }
`;

const StyledPaymentPayments = styled(PaymentPayments)`
  min-height: calc(41px * 2 + 39px);

  .ant-empty {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .ant-empty-image {
    margin-bottom: 3px;
  }

  &&& {
    .ant-empty-image,
    .ant-empty-image svg {
      height: 30px;
    }
  }

  .ant-table-placeholder {
    max-height: translateY(calc(39px * 2));
  }
`;

const StyledPaymentLogs = styled(PaymentLogs)`
  flex: 1;

  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-container,
  .ant-table-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  table .ant-table-placeholder td {
    border-bottom: none;
  }

  .ant-descriptions-item-content,
  .ant-descriptions-item-label {
    font-size: 1.12rem;
  }
`;

const StyledTable = styled(Table)`
  border: 1px solid rgb(240, 240, 240);

  [data-theme='dark'] & {
    border-color: #303030;
  }
` as typeof Table;
