import useAxios from '@hooks/useAxios';
import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accessTokenState, userInfoState } from '@recoil/auth';
import currentSiteState from '@recoil/current-site';
import PrivateRoute from './PrivateRoute';
import { publicRoutes, privateRoutes } from './routes';
import { QueryParamProvider } from 'use-query-params';
import { NesProvider } from '@contexts/NesContext';
import ServiceWrapper from '@components/ServiceWrapper';
import checkPasswordExpiration from './checkPasswordExpiration';

export default function Router() {
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <NesProvider>
          <TheSwitch />
        </NesProvider>
      </QueryParamProvider>
    </BrowserRouter>
  );
}

function TheSwitch() {
  const axios = useAxios();
  const history = useHistory();
  const setUserInfo = useSetRecoilState(userInfoState);
  const setCurrentSite = useSetRecoilState(currentSiteState);
  const accessToken = useRecoilValue(accessTokenState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!accessToken) {
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .get('/admins/me')
      .then(({ data }) => {
        setUserInfo(data);
        setCurrentSite((site) => data.permissionGroup.site || site);
        checkPasswordExpiration({ data, history });
      })
      .then(() => setLoading(false));
  }, [accessToken, axios, history, setCurrentSite, setUserInfo]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        {/*<EyevacsLogo style={{ width: 200 }} />*/}
      </div>
    );
  }

  return (
    <ServiceWrapper>
      <Switch>
        {publicRoutes.map(
          ({ exact = true, path, component, ...otherProps }) => (
            <Route
              key={`public-route-${path}`}
              exact={exact}
              path={path}
              component={component}
              {...otherProps}
            />
          )
        )}
        {privateRoutes.map(
          ({ exact = true, path, component, ...otherProps }) =>
            path && (
              <PrivateRoute
                key={`private-route-${path}`}
                exact={exact}
                path={path}
                component={component}
                {...otherProps}
              />
            )
        )}
      </Switch>
    </ServiceWrapper>
  );
}
