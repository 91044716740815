import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { accessTokenState } from '@recoil/auth';
import currentSiteState from '@recoil/current-site';
import { Alert } from 'antd';

interface IPrivateRoute extends RouteProps {
  component?: React.ComponentType<any>;
  children?: React.ReactNode;
}

const PrivateRoute = ({
  component: Component = () => null,
  children,
  ...rest
}: IPrivateRoute) => {
  const currentSite = useRecoilValue(currentSiteState);
  const token = useRecoilValue(accessTokenState);

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          currentSite ? (
            <Component {...props} />
          ) : (
            <Alert
              message="주차장을 선택해 주세요!"
              description="서비스를 이용하기 전, 왼쪽 메뉴에서 주차장을 먼저 선택해 주세요!"
              type="warning"
              showIcon
            />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
