import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardProps,
  Descriptions,
  DescriptionsProps,
  Form,
  FormItemProps,
  Input,
  InputProps,
  message,
  Skeleton,
  Typography,
} from 'antd';

import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { DescriptionsItemProps } from 'antd/lib/descriptions/Item';

import { renderDate } from '@utils/index';
import { PHONE_REGEX } from '@utils/regex';
import { VehicleAccess, User } from '@utils/models';

import useSWR from 'swr';
import AxiosStatic from 'axios';
import useAxios from '@hooks/useAxios';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from '@routes/routes.constants';

// Descriptions 컴포넌트가 table/tr/td로 대체되기 때문에 JSX.Element로 반환해야 함
// https://github.com/ant-design/ant-design/blob/86aaacdee11fc2a4f2ce26e07774cf49bf0334e2/components/descriptions/Row.tsx#L104

type RIProps = FormItemProps & {
  key: keyof VehicleAccess['info'] | 'remarks';
  label: DescriptionsItemProps['label'];
  disabled: InputProps['disabled'];
  type?: InputProps['type'];
};

type InfoCardProps = CardProps & {
  card?: FC<CardProps>;
  descriptions?: FC<DescriptionsProps>;
};

const renderCard: FC<InfoCardProps> = ({
  children,
  card: C = PaddinglessCard,
  descriptions: D = BorderlessDescriptions,
  ...props
}) => (
  <C {...props}>
    <D bordered column={2} size="small">
      {children}
    </D>
  </C>
);

const renderItem = ({ children, ...props }: DescriptionsItemProps) => (
  <Descriptions.Item {...props} key={props.label?.toString()}>
    {children || <Typography.Text type="secondary">없음</Typography.Text>}
  </Descriptions.Item>
);

const renderInput = ({ key, label, disabled, type, ...props }: RIProps) => (
  <Descriptions.Item
    label={label}
    key={label?.toString()}
    contentStyle={{ padding: 0 }}
  >
    <Form.Item name={key} noStyle {...props}>
      <Input
        type={type}
        disabled={disabled}
        bordered={false}
        placeholder="없음"
      />
    </Form.Item>
  </Descriptions.Item>
);

interface Data {
  vehicleAccess: VehicleAccess;
  user: User;
}

interface Option {
  card?: InfoCardProps;
  revalidate?: () => void;
}

type I = Data & Option;

// 차량 정보 카드

export const VAInfo: FC<I> = ({ card, vehicleAccess: va, revalidate }) => {
  const axios = useAxios();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);

  const onFinish = useCallback(
    ({ remarks, ...info }: any) =>
      axios
        .patch(`/vehicle-accesses/${va._id}`, { info, remarks })
        .then(() => {
          revalidate?.();
          setDisabled(true);
          message.success('차량 정보를 수정했습니다.');
        })
        .catch((err) =>
          message.error(
            AxiosStatic.isAxiosError(err)
              ? err.response?.data?.message
              : '차량 정보 수정을 실패했습니다.'
          )
        ),
    [axios, revalidate, va._id]
  );

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity) => {
    console.log(errorFields);
    message.error(errorFields.flatMap((e) => e.errors).join('\n'));
  };

  const renderField = useCallback(
    (props: Omit<RIProps, 'disabled'>) => renderInput({ ...props, disabled }),
    [disabled]
  );

  const email: Partial<RIProps> = {
    type: 'email',
    rules: [{ type: 'email', message: '올바른 이메일을 입력해 주세요.' }],
  };

  const tel: Partial<RIProps> = {
    type: 'tel',
    normalize: (value, prevValue) => {
      if (value.length > 11) return prevValue;
      else return value;
    },
    rules: [
      // { required: true, message: '전화번호를 입력하세요!' },
      {
        validator: (_, value) =>
          !value
            ? Promise.resolve()
            : !!Number(value)
            ? value.length > 11
              ? Promise.reject('최대 11자리까지만 입력할 수 있어요.')
              : !value.match(PHONE_REGEX)
              ? Promise.reject('형식에 맞는 전화번호를 입력해 주세요!')
              : Promise.resolve()
            : Promise.reject('숫자만 입력해 주세요.'),
      },
    ],
  };

  const extra = [
    <Button
      key="default"
      type="default"
      onClick={() => setDisabled(true)}
      style={{ display: disabled ? 'none' : undefined }}
    >
      취소
    </Button>,
    <Button
      key="primary"
      type="primary"
      style={{ marginLeft: '0.5rem' }}
      onClick={() => (disabled ? setDisabled(false) : form.submit())}
    >
      {disabled ? '수정' : '적용'}
    </Button>,
  ];
  const id = (
    <Link to={`${PRIVATE_ROUTE.VEHICLE_MANAGE}/info/${va._id}`}>
      <code>{va._id}</code>
    </Link>
  );

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{ ...va.info, remarks: va.remarks }}
    >
      {renderCard({
        ...card,
        title: '차량 정보',
        extra,
        children: [
          renderItem({ label: '차량 ID', span: 2, children: id }),
          renderField({ key: 'name', label: '이름' }),
          renderField({ key: 'company', label: '회사' }),
          renderField({ key: 'phone', label: '전화번호', ...tel }),
          renderField({ key: 'department', label: '부서' }),
          renderField({ key: 'email', label: '이메일', ...email }),
          renderField({ key: 'position', label: '직책' }),
          renderField({ key: 'remarks', label: '비고' }), // va.info.remarks 아님!!
        ],
      })}
    </Form>
  );
};

// 방문예약 정보 카드

export const ReservationInfo: FC<I> = ({ card, vehicleAccess: va }) => {
  const res = va.reservations[va.reservations.length - 1];
  if (!res) return <></>;

  const id = (
    <Link to={`${PRIVATE_ROUTE.RESERVATION_MANAGE}/${res._id}`}>
      <code>{res._id}</code>
    </Link>
  );

  const visitLimit = `${res.visitCount || '-'} / ${
    (res.visitLimit < 0 ? '제한 없음' : res.visitLimit) || '-'
  }`;

  const ownerName =
    res.ownerModel === 'User' ? res.owner.info.name : res.owner.name;

  return renderCard({
    ...card,
    title: '방문예약 정보',
    children: [
      renderItem({ label: '방문예약 ID', children: id, span: 2 }),
      renderItem({ label: '이름', children: res.user.name }),
      renderItem({ label: '회사', children: res.user.company }),
      renderItem({ label: '전화번호', children: res.user.phone }),
      renderItem({ label: '부서', children: res.user.department }),
      renderItem({ label: '차량구분', children: res.vehicleType }),
      renderItem({ label: '직책', children: res.user.position }),
      renderItem({ label: '시작일', children: renderDate(res.startAt) }),
      renderItem({ label: '만료일', children: renderDate(res.endAt) }),
      renderItem({ label: '방문횟수', children: visitLimit }),
      renderItem({ label: '예약자', children: ownerName }),
      renderItem({ label: '방문목적', children: res.purpose }),
      renderItem({ label: '비고', children: res.remarks }),
    ],
  });
};

// 정기권 정보 카드

export const SeasonTicketInfo: FC<I> = ({ card, vehicleAccess: va }) => {
  const ticket = va.payment.seasonTicket;
  if (!ticket) return <></>;

  const id = (
    <Link to={`${PRIVATE_ROUTE.TICKET_MANAGE}/${ticket._id}`}>
      <code>{ticket._id}</code>
    </Link>
  );

  return renderCard({
    ...card,
    title: '정기권 정보',
    children: [
      renderItem({ label: '정기권 ID', children: id, span: 2 }),
      renderItem({ label: '이름', children: ticket.user?.name }),
      renderItem({ label: '주소', children: ticket.user?.address }),
      renderItem({ label: '전화번호', children: ticket.user?.phone }),
      renderItem({ label: '부서', children: ticket.user?.position }),
      renderItem({ label: '이메일', children: ticket.user?.email }),
      renderItem({ label: '직책', children: ticket.user?.department }),
      renderItem({ label: '차량구분', children: ticket.vehicleType }),
      renderItem({ label: '차량종류', children: ticket.vehicleModelName }),
      renderItem({ label: '시작시간', children: renderDate(ticket.startAt) }),
      renderItem({ label: '만료시간', children: renderDate(ticket.endAt) }),
      renderItem({ label: '방문목적', children: ticket.purpose }),
      renderItem({ label: '비고', children: ticket.remarks }),
    ],
  });
};

// 사용자 정보 카드

const UserInfo: FC<I> = ({ card, user }) => {
  if (!user) return <></>;

  const id = (
    <Link to={`${PRIVATE_ROUTE.USER_GENERAL_MANAGE}/${user._id}`}>
      <code>{user._id}</code>
    </Link>
  );

  return renderCard({
    ...card,
    title: '사용자 정보',
    children: [
      renderItem({ label: '사용자 ID', children: id, span: 2 }),
      renderItem({ label: '이름', children: user.info.name }),
      renderItem({ label: '입주사', children: user.friend?.name ?? '-' }),
      renderItem({ label: '전화번호', children: user.info.phone }),
      renderItem({ label: '부서', children: user.info.department }),
      renderItem({ label: '이메일', children: user.email }),
      renderItem({ label: '직책', children: user.info.position }),
    ],
  });
};

// 통합-정보 카드

export type InfoProps = Option & { vaId: string };

export const Info: FC<InfoProps> = ({ vaId, card, revalidate }) => {
  const { data, revalidate: rev } = useSWR<Omit<Data, 'revalidate'>>(
    vaId ? `/vehicle-accesses/${vaId}` : null
  );

  if (!data) return <Skeleton />;
  const i = { ...data, card, revalidate: () => [rev(), revalidate?.()] };

  return (
    <>
      <VAInfo {...i} />
      <SeasonTicketInfo {...i} />
      <ReservationInfo {...i} />
      <UserInfo {...i} />
    </>
  );
};

export default Info;

const PaddinglessCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

const BorderlessDescriptions = styled(Descriptions)`
  & > .ant-descriptions-view {
    border: none;
    margin-top: 1px;
  }
`;
