import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Card, Input, message, Table, Image as AntImage } from 'antd';
import { useSWRInfinite } from 'swr';

import useAxios from '@hooks/useAxios';
import ManualEntryModal from '../modals/ManualEntryModal';
import { DateValue, IMAGE_CDN } from '@utils';
import { userInfoState } from '@recoil/auth';
import { useRecoilValue } from 'recoil';
import AccessLogText from '@components/AccessLogText';

export interface VehicleTableProps {
  type: string;
  vehicleAccess: any;
  revalidate: () => void;
  entryBefore?: DateValue;
  exitAfter?: DateValue;
  onPlateNumberUpdated?: (_id: string) => any;
}

export default ({
  type,
  vehicleAccess,
  revalidate,
  entryBefore,
  exitAfter,
  onPlateNumberUpdated,
}: VehicleTableProps) => {
  const axios = useAxios();
  const userInfo = useRecoilValue(userInfoState);

  const [selectedVehicle, setSelectedVehicle] = useState<any>();
  const [vehicleNumber, setVehicleNumber] = useState<string>();
  const [vehicleQuery, setVehicleQuery] = useState<string>('');

  const [newVehicleNumber, setNewVehicleNumber] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const getKey = (pageIndex: number, previousPageData: any) => {
    if (!vehicleAccess || vehicleNumber === undefined) {
      return null;
    }
    if (pageIndex !== 0 && !previousPageData.meta.nextPage) {
      return null;
    }
    return `/vehicle-accesses?${
      vehicleNumber ? `vehicle=${vehicleNumber}&` : ''
    }siteId=${vehicleAccess?.site._id}&status=${
      type === 'entry' ? 'inParking' : 'noEntry'
    }&count=10&page=${pageIndex + 1}${
      entryBefore ? `&entryBefore=${entryBefore}` : `&exitAfter=${exitAfter}`
    }`;
  };

  const { isValidating, data, size, setSize } = useSWRInfinite(getKey);

  useEffect(() => {
    if (!vehicleAccess || !vehicleAccess?.vehicle) {
      return;
    }
    const vehicle = vehicleAccess.vehicle.slice(-4);
    setVehicleNumber(vehicle);
    setVehicleQuery(vehicle);
  }, [vehicleAccess]);

  const onScrollTableWrapper = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setSize(size + 1);
    }
  };

  const onClickEdit = async () => {
    if (!selectedVehicle?._id) {
      message.error('차량번호를 수정하실 차량을 선택해 주세요.');
      return;
    }

    if (!newVehicleNumber) {
      message.error('차량번호를 입력해 주세요.');
      return;
    }

    try {
      await axios.patch(`/vehicle-accesses/${selectedVehicle._id}`, {
        vehicle: newVehicleNumber,
      });

      message.success('차량번호 수정이 완료되었습니다.');
      revalidate();
      onPlateNumberUpdated?.(selectedVehicle._id);
    } catch (error: any) {
      console.log(error);
      message.error('차량번호 수정 중 에러가 발생했습니다.');
    }
  };

  if (['friend', 'owner', 'arcade'].includes(userInfo.permissionGroup.tag))
    return <></>;

  return (
    <StyledCard>
      <Container onScroll={onScrollTableWrapper}>
        <CardHeader>
          <Input.Search
            style={{ width: 200 }}
            placeholder="차량번호로 검색"
            value={vehicleQuery}
            onChange={({ target: { value } }) => setVehicleQuery(value)}
            onSearch={() => setVehicleNumber(vehicleQuery)}
          />
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            수동{type === 'entry' ? '입차' : '출차'}
          </Button>
        </CardHeader>
        <Table
          size="small"
          dataSource={data?.flatMap?.((v) => v.vehicleAccesses)}
          loading={isValidating}
          rowKey={(record) => record._id}
          columns={[
            {
              key: 'image',
              title: '사진',
              dataIndex: '',
              render: (va: any) =>
                va[type]?.image?.file ? (
                  <AntImage
                    src={`${IMAGE_CDN}/${va[type]?.image?.file}`}
                    style={{ height: 80 }}
                  />
                ) : (
                  <span>수동{type === 'entry' ? '입차' : '출차'}</span>
                ),
            },
            {
              key: 'vehicle',
              title: '차량번호',
              dataIndex: 'vehicle',
              render: (vehicle) => (vehicle ? vehicle : '미인식'),
            },
            {
              title: '권종',
              dataIndex: '',
              key: 'type',
              render: () => '일반',
            },
            {
              title: `${type === 'entry' ? '입차' : '출차'}일시`,
              key: 'time',
              render: (va) => (
                <AccessLogText log={type === 'entry' ? va.entry : va.exit} />
              ),
            },
          ]}
          pagination={false}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedVehicle
              ? [selectedVehicle._id as React.ReactText]
              : [],
            onChange: (keys, data) => {
              setSelectedVehicle(data[0]);
            },
          }}
          onRow={(record, index) => {
            return {
              onClick: () => {
                setSelectedVehicle(record);
                setNewVehicleNumber(record.vehicle);
              },
            };
          }}
        />
      </Container>
      <CardFooter>
        <Input
          value={newVehicleNumber}
          onChange={(e) => setNewVehicleNumber(e.target.value)}
        />
        <Button type="primary" onClick={onClickEdit}>
          차량번호 수정
        </Button>
      </CardFooter>
      <ManualEntryModal
        visible={isModalOpen}
        setVisible={setIsModalOpen}
        revalidate={() => revalidate()}
        isEntry={type === 'entry'}
        initialVehicle={vehicleAccess?.vehicle}
        goPage={false}
      />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  flex: 1;
  height: 100%;

  &&& {
    position: relative;

    .ant-card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Container = styled.div`
  max-height: 325px;
  overflow: auto;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CardFooter = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

  & > input {
    margin-right: 8px;
  }
`;
