import { atom } from 'recoil';

export enum SIDEBARS {
  OPEN,
  COLLAPSED,
}

const sidebarState = atom<SIDEBARS>({
  key: 'sidebarState',
  default: SIDEBARS.OPEN,
});

export default sidebarState;
