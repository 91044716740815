import { message } from 'antd';
import { Site } from '@utils/models';

import { useRecoilCallback } from 'recoil';
import currentSiteState from '@recoil/current-site';

import { Path } from 'history';
import { useHistory } from 'react-router';

interface ForwardingLinkCallbackProps {
  type: string;
  site: Site;
  path: Path;
}

const useForwardingLink = () => {
  const history = useHistory();

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ type, site, path }: ForwardingLinkCallbackProps) => {
        const currentSite = await snapshot.getPromise(currentSiteState);
        if (site && site._id !== currentSite?._id) {
          set(currentSiteState, site);
          message.warn(
            `다른 주차장의 ${type}입니다. ${site.name} 주차장으로 이동합니다.`
          );
        }
        history.push(path);
      },
    [history]
  );
};

export default useForwardingLink;
