import React, { memo } from 'react';
import { Avatar, AvatarProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import md5 from 'md5';

export interface ProfileImageProps extends Omit<AvatarProps, 'icon' | 'src'> {
  email?: string;
  displayText?: boolean;
}

// see: https://en.gravatar.com/site/implement/
export default memo(
  ({ email, displayText = false, ...props }: ProfileImageProps) => {
    if (!email && displayText) {
      return <span>-</span>;
    }

    const hash = md5(email?.trim().toLowerCase() || '');
    const avatar = (
      <Avatar
        {...props}
        icon={<UserOutlined />}
        alt={`Gravatar image of ${email}`}
        src={`https://www.gravatar.com/avatar/${hash}?s=256&d=404`}
      />
    );

    return displayText ? (
      <>
        {avatar}
        <span style={{ marginLeft: 5 }}>{email}</span>
      </>
    ) : (
      avatar
    );
  }
);
