import { atom } from 'recoil';

export enum FOOTERS {
  VISIBLE,
  HIDDEN,
}

const footerState = atom<FOOTERS>({
  key: 'footerState',
  default: FOOTERS.VISIBLE,
});

export default footerState;
