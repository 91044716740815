import { FC, useCallback, useState } from 'react';
import { Button, ButtonProps, message, Modal } from 'antd';
import useAxios from '@hooks/useAxios';
import { VehicleAccess } from '@utils/models';

interface Props extends ButtonProps {
  va: VehicleAccess;
  revalidate: () => void;
}

const DeleteButton: FC<Props> = ({ va, revalidate, ...props }) => {
  const axios = useAxios();
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    Modal.confirm({
      content: '해당 차량 기록을 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk: async () => {
        if (!va) return;
        setLoading(true);

        try {
          await axios.delete(`/vehicle-accesses/${va._id}`);
          message.success('차량 기록이 삭제되었습니다.');
          revalidate();
        } catch (err: any) {
          message.error(err?.response?.data?.message || '문제가 발생했습니다.');
        } finally {
          setLoading(false);
        }
      },
    });
  }, [axios, revalidate, va]);

  return (
    <Button
      {...props}
      onClick={onClick}
      loading={loading}
      style={{ color: '#ff4d4f', borderColor: '#ff4d4f' }}
    >
      차량 삭제
    </Button>
  );
};

export default DeleteButton;
