import 'object.values/auto';
import 'object.entries/auto';

import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';

import * as serviceWorker from './serviceWorker';
import App from './App';

import packageJson from '../package.json';
console.log(`version: ${packageJson.version}`);

WebFont.load({
  custom: {
    families: ['LGSmHa'],
    urls: ['/fonts/LGSmHa.css'],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
