import styled from 'styled-components';

export default styled.div`
  width: 100%;
  padding: 15px;
  border: 1px solid rgb(228, 230, 233);
  background-color: white;
  height: 100%;
  margin-bottom: 20px;

  @media only screen and (max-width: 767px) {
    padding: 15px 12px;
  }

  @media (max-width: 580px) {
    padding: 15px 10px;
  }

  .ant-tabs-tab {
    font-size: 14px;
  }

  body[data-theme='dark'] & {
    border-color: #303030;
    background-color: rgba(37, 42, 47, 0.65);
  }
`;
