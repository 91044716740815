import React, { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Layout, Menu } from 'antd';

import { PRIVATE_ROUTE } from '@routes/routes.constants';
import sidebarState, { SIDEBARS } from '@recoil/sidebar';
import { userInfoState } from '@recoil/auth';
import type { Menu as MenuType } from '@utils/models';
import useWindowSize from '@hooks/useWindowSize';

import { ReactComponent as EyevacsLogo } from '@assets/logo.svg';

import Icon from './Icon';
import GlobalSiteSelect from './GlobalSiteSelect';
import currentSiteState from '@recoil/current-site';
import { useEnabled } from '@pages/Parking/MannedSettlement';

const { Sider } = Layout;
const { SubMenu } = Menu;

const MENU_HIDDEN = 'hidden';
const MENU_DESKTOP_ONLY = 'desktop';

const Sidebar = React.memo(() => {
  const history = useHistory();
  const { pathname } = useLocation();
  const currentSite = useRecoilValue(currentSiteState)!;
  const sidebar = useRecoilValue(sidebarState);
  const { permissionGroup } = useRecoilValue(userInfoState);
  const { width: screenWidth = 1920 } = useWindowSize();

  const onClickLogo = useCallback(
    () => history.push(PRIVATE_ROUTE.MAIN),
    [history]
  );

  const onClickMenu = useCallback(
    (goto: string = '') => {
      if (pathname !== goto) history.push(goto);
      else {
        history.replace('/loading');
        setTimeout(() => history.replace(goto), 1);
        // 현재 페이지로 다시 이동 시 소프트 새로고침
      }
    },
    [history, pathname]
  );

  // FIXME: 1차 테스트 임시
  const hiddenChildren = useMemo(() => ['후불 결제'], []);

  // FIXME: MongoDB 내부의 permissionGroup.menu 일괄 수정
  const addedChildren: [string, MenuType][] = [];
  // Object.entries({
  //   '할인권 충전내역': {
  //     tags: [],
  //     name: 'QR Code 할인권',
  //     value: '/friend-discount-qrcode',
  //     children: [],
  //   },
  // });

  // 용산사옥 커스텀 기능
  // 1-2. 메뉴트리 방문예약 관리 명칭변경 : (기존) 방문예약 관리 -> (변경) 주차 할인 관리
  const isYongsanCustom = useMemo(
    () => currentSite?._id === 'LG_UPLUS_YONGSAN',
    [currentSite?._id]
  );

  const rename = useMemo(
    () => (isYongsanCustom ? [['방문예약 관리', '주차 할인 관리']] : []),
    [isYongsanCustom]
  );

  const isMannedSettlementEnabled = useEnabled();

  const hidden = useCallback(
    (menu: MenuType) =>
      !menu.tags.includes(MENU_HIDDEN) &&
      !(menu.tags.includes(MENU_DESKTOP_ONLY) && screenWidth < 500),
    [screenWidth]
  );

  const reduce = useCallback(
    (m: MenuType, _: number, arr: MenuType[]) => {
      if (hiddenChildren.some((n) => m.name?.includes(n))) return [];
      const n = addedChildren.find(([n]) => m.name?.includes(n))?.[1];
      return n && arr.every((a) => a.value !== n.value) ? [m, n] : [m];
    },
    [hiddenChildren, addedChildren]
  );

  const menu = useMemo(
    () =>
      permissionGroup.menu.filter(hidden).map((m) => ({
        ...m,
        children: m.children.filter(hidden).flatMap(reduce),
        name: rename.find((r) => r[0] === m.name)?.[1] || m.name,
      })),
    [permissionGroup.menu, hidden, reduce, rename]
  );

  return (
    <SliderWrapper>
      <Sider
        collapsed={false}
        defaultCollapsed={false}
        width={260}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: sidebar === SIDEBARS.COLLAPSED ? -260 : 0,
          zIndex: 999,
        }}
        trigger={() => null}
      >
        <Logo onClick={onClickLogo}>
          <EyevacsLogo />
        </Logo>
        <code
          id="git-info"
          style={{
            position: 'absolute',
            left: 10,
            bottom: 5,
            color: '#eee',
            fontSize: '0.5rem',
            visibility:
              process.env.NODE_ENV === 'development' ? 'visible' : 'hidden',
          }}
        >
          branch: {process.env.REACT_APP_GIT_BRANCH} <br />
          date: {process.env.REACT_APP_GIT_DATE} <br />
          head: {process.env.REACT_APP_GIT_HEAD} <br />
        </code>
        <div
          style={{
            padding: '1rem 1.5rem',
          }}
        >
          <GlobalSiteSelect />
        </div>
        <OverflowedMenu
          theme="dark"
          mode="inline"
          activeKey={pathname}
          selectedKeys={[pathname]}
          forceSubMenuRender={true}
        >
          {isMannedSettlementEnabled && (
            <Menu.Item
              key="/manned-settlement"
              icon={<Icon type="CreditCardOutlined" />}
              onClick={() => onClickMenu('/manned-settlement')}
            >
              유인정산
            </Menu.Item>
          )}
          {menu.map((parent, i) =>
            parent.children && parent.children.length > 0 ? (
              <SubMenu
                key={i}
                icon={parent.icon && <Icon type={parent.icon} />}
                title={parent.name}
                style={{ fontSize: 14 }}
              >
                {parent.children.map((children) => (
                  <Menu.Item
                    key={children.value}
                    icon={children.icon && <Icon type={children.icon} />}
                    onClick={() => onClickMenu(children.value)}
                  >
                    {children.name}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              parent.value && (
                <Menu.Item
                  key={parent.value}
                  icon={parent.icon && <Icon type={parent.icon} />}
                  onClick={() => onClickMenu(parent.value)}
                >
                  {parent.name}
                </Menu.Item>
              )
            )
          )}
        </OverflowedMenu>
      </Sider>
    </SliderWrapper>
  );
});

export default Sidebar;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  user-select: none;
  padding: 15px 0 5px;

  & > svg {
    width: 210px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;

const OverflowedMenu = styled(Menu)`
  overflow-y: auto;
`;

const SliderWrapper = styled.div`
  & > aside .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;
